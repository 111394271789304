import React from "react";
import { Button } from "../atoms";
import showFilePicker from "./FilePicker";
import type { MediaPickerFile } from "./MediaPicker";

interface Props {
  onSelect: (item: MediaPickerFile[]) => void;
}

export default function FilePickerButton({ onSelect }: Props) {
  return (
    <Button
      label="Upload Photos & Documents"
      size="small"
      variant="clear"
      outline
      icon="upload"
      testID="docs-uploader"
      onPress={async () => await showFilePicker(onSelect)}
    />
  );
}
