import React, { type ReactNode } from "react";
import { ActivityIndicator, type FlexAlignType, View } from "react-native";
import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import { type UnitSize, getSpaceUnits } from "../quarks/Spacer";
import Text from "../quarks/Text";
import { ColorPlacementProvider, useStyles, useTheme } from "../style";
import type { Color } from "../style/theme/colors";

export type ButtonVariant = "solid" | "clear" | "rounded";
type ButtonSize = "large" | "small" | "xsmall";

const specs = {
  size: { large: 12, small: 8, xsmall: 6.5 }
};

export interface Props {
  testID: string;
  eventTargetName?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  color?: Color;
  label?: string;
  icon?: IconName;
  iconPlacement?: "left" | "right";
  iconSpacing?: UnitSize;
  outline?: boolean;
  fill?: boolean | number;
  grow?: boolean | number;
  onPress?: () => void;
  onLongPress?: () => void;
  onPressOut?: () => void;
  delayLongPress?: number;
  fullWidth?: boolean;
  onPressDisabled?: () => void;
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;
  loading?: boolean;
  alignSelf?: FlexAlignType;
  labelColor?: Color;
  minWidth?: number;
}

export default function Button({
  testID,
  eventTargetName = "Button",
  disabled,
  variant = "solid",
  size = "large",
  color = "primary",
  label,
  icon,
  iconPlacement = "left",
  iconSpacing = "compact",
  outline,
  fill,
  grow,
  onPress,
  onLongPress,
  delayLongPress,
  onPressOut,
  onPressDisabled,
  fullWidth,
  leftAccessory,
  rightAccessory,
  loading,
  alignSelf,
  labelColor,
  minWidth
}: Props) {
  if (disabled) color = "disabled";

  const theme = useTheme();
  const tintColor = theme.getColor(color, "fill");
  const styles = useStyles(
    (theme) => {
      if (fill === true) fill = 1;
      if (grow === true) grow = 1;
      const btnSize = theme.getUnits(specs.size[size]);

      const btnHPadding = theme.getUnits(
        ((variant === "rounded" && size !== "xsmall") || size === "large") &&
          !fill
          ? 4
          : 2
      );
      const btnVPadding = theme.getUnits(size === "xsmall" ? 0 : 1);
      const isIconOnly = !label && !leftAccessory && !rightAccessory;

      return {
        container: {
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          minHeight: btnSize,
          borderRadius: theme.getUnits(1),
          backgroundColor: tintColor,
          borderColor: "transparent",
          borderWidth: 1,
          width: fullWidth ? "100%" : undefined,
          paddingVertical: btnVPadding,
          paddingHorizontal: btnHPadding,
          alignSelf,
          minWidth,
          ...(isIconOnly && { paddingHorizontal: 0, width: btnSize }),
          ...(fill && { flex: fill }),
          ...(grow && { flexGrow: grow }),
          ...(variant === "rounded" && { borderRadius: btnSize / 2 })
        },
        containerTransparent: { backgroundColor: "transparent" },
        containerOutline: { borderColor: tintColor },
        containerHovered: { opacity: 0.95 },
        containerPressed: { opacity: 0.8 },
        label: {
          ...theme.getFontSize(3.5),
          textAlign: "center",
          ...(icon && iconPlacement === "left"
            ? { marginRight: 0, marginLeft: getSpaceUnits(iconSpacing) }
            : undefined),
          ...(icon && iconPlacement === "right"
            ? { marginLeft: 0, marginRight: getSpaceUnits(iconSpacing) }
            : undefined)
        },
        loading: {
          position: "absolute",
          right: 0,
          bottom: 0,
          top: 0,
          alignItems: "center",
          justifyContent: "center",
          paddingHorizontal: theme.getUnits(3)
        }
      };
    },
    [variant, size, tintColor, fill, fullWidth, grow, fill, minWidth]
  );
  const isDisabled = loading || (onPressDisabled ? false : disabled);
  const isTransparent = outline || variant === "clear";
  const iconNode = !!icon && (
    <Icon
      size={size === "xsmall" ? "tiny" : "small"}
      name={icon}
      variant="solid"
      color={labelColor}
    />
  );

  return (
    <ColorPlacementProvider
      color={color}
      use={isTransparent ? "fill" : "placement"}
    >
      <Pressable
        testID={testID}
        eventEntityType="Button"
        eventTargetName={eventTargetName}
        disabled={isDisabled}
        onPress={loading ? undefined : disabled ? onPressDisabled : onPress}
        onLongPress={loading || disabled ? undefined : onLongPress}
        onPressOut={disabled ? undefined : onPressOut}
        delayLongPress={delayLongPress}
        style={({ pressed, hovered }) => {
          return [
            styles.container,
            outline && styles.containerOutline,
            isTransparent && styles.containerTransparent,
            !isDisabled && hovered && styles.containerHovered,
            !isDisabled && pressed && styles.containerPressed
          ];
        }}
      >
        {iconPlacement === "left" && iconNode}
        {leftAccessory}
        {!!label && (
          <Text
            color={labelColor}
            selectable={false}
            style={styles.label}
            weight="bold"
            testID={`${testID}-label`}
          >
            {label}
          </Text>
        )}
        {rightAccessory}
        {iconPlacement === "right" && iconNode}
        {loading && (
          <View style={styles.loading}>
            <ActivityIndicator color={isTransparent ? tintColor : "#fff"} />
          </View>
        )}
      </Pressable>
    </ColorPlacementProvider>
  );
}
