import {
  CommonActions,
  type NavigationAction,
  type NavigationState,
  type ParamListBase,
  type PartialState,
  findFocusedRoute,
  getPathFromState,
  getStateFromPath,
  useNavigation,
  useNavigationState
} from "@react-navigation/native";
import { isString } from "lodash";
import { useCallback, useContext, useEffect } from "react";
import { BackHandler } from "react-native";
import { LinkingContext, navigationRef } from "./delegate";

const IS_TESTING =
  process.env.IS_TESTING === "true" || process.env.CONFIG_ENV === "e2e";

/** @deprecated */
export const LEGACY_ROUTE = "LEGACY_Router";
/** @deprecated */
export const BACK_TO_NAV_STATE = "__backToNav";

export function defaultCanGoBack() {
  return navigationRef.isReady() && navigationRef.canGoBack();
}

export function safeExit<T extends NavigationAction>(action: T): T {
  return { ...action, payload: { ...action.payload, safeExit: true } };
}

type BackOptions = {
  safeExit?: boolean;
};

export function defaultBackHandler(opt: BackOptions = {}) {
  if (!navigationRef.isReady()) return false;

  if (navigationRef.canGoBack()) {
    if (opt.safeExit) navigationRef.dispatch(safeExit(CommonActions.goBack()));
    else navigationRef.goBack();
  } else {
    BackHandler.exitApp();
  }

  return true;
}

export function defaultResetHome(
  ...next: PartialState<NavigationState>["routes"] | NavigationState["routes"]
) {
  if (!navigationRef.isReady()) return false;
  const routes = [{ name: "Home" }];
  next.forEach((route) => route.name && routes.push(route));
  navigationRef.resetRoot({ routes });
  return true;
}

export function isLegacyRoute() {
  if (navigationRef.isReady()) {
    return navigationRef.getCurrentRoute()?.name === LEGACY_ROUTE;
  }

  return true;
}

export function useCurrentUrl() {
  const getPath = useGetPathFromState();
  return useNavigationState(getPath);
}

export function useGetPathFromState() {
  const { options } = useContext(LinkingContext);
  const toPath = options?.getPathFromState ?? getPathFromState;
  return useCallback((state: NavigationState<ParamListBase>) => {
    return state ? toPath(state, options?.config) : "/";
  }, []);
}

export function useGetRouteFromPath() {
  const { options } = useContext(LinkingContext);
  const toState = options?.getStateFromPath ?? getStateFromPath;

  return useCallback((path: string) => {
    const state = toState(path, options?.config as any);
    if (!state) {
      if (IS_TESTING) {
        // ignore if testing
        return;
      }
      throw new Error(`Failed to parse Navigation State: path=${path}`);
    }

    return findFocusedRoute(state);
  }, []);
}

type ScreenTitleOptions = {
  title?: string | null;
  prefix?: string;
};
export function useScreenTitle(opt: ScreenTitleOptions) {
  const nav = useNavigation();
  useEffect(() => {
    const title = [opt.prefix, opt.title].filter(isString).join(": ");
    nav.setOptions({ title });
  }, [nav, opt.prefix, opt.title]);
}
