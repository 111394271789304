import { init } from "../hooks";
import { logger } from "../logger";
import ConsoleIntegration from "./console";
import crashlytics from "./crashlytics";
import featureFlags from "./featureFlags";
import firebase from "./firebase";
import install from "./install";
import lifecycle from "./lifecycle";
import listenlayer from "./listenlayer";
import mixpanel from "./mixpanel";
import rudderstack from "./rudderstack";
import sentry from "./sentry";

type MaybePromise<T> = T | Promise<T>;

const integrations = (<K extends string>(
  o: Record<K, () => MaybePromise<() => MaybePromise<void>>>
) => o)({
  rudderstack,
  crashlytics,
  firebase,
  lifecycle,
  install,
  sentry,
  featureFlags,
  listenlayer,
  console: ConsoleIntegration,
  mixpanel
});

export type Integration = keyof typeof integrations;

const loaded: Partial<Record<Integration, () => MaybePromise<void>>> = {};

export async function integrate(
  plugins: Partial<Record<Integration, boolean>>
) {
  const [loadables, unloadables] = Object.keys(plugins).reduce<
    [Integration[], Integration[]]
  >(
    ([l, ul], name) =>
      plugins[name as Integration]
        ? [[...l, name as Integration], ul]
        : [l, [...ul, name as Integration]],
    [[], []]
  );
  await Promise.all([load(loadables, false), unload(unloadables)]);
  init();
}

export async function load(
  plugins: Integration | Integration[],
  doInit = true
) {
  await Promise.all(
    (Array.isArray(plugins) ? plugins : [plugins]).map(async (name) => {
      if (typeof loaded[name as Integration] === "undefined") {
        logger.info("[dekigoto:integrations:loading]", name);
        loaded[name] = await integrations[name]();
        logger.info("[dekigoto:integrations:loaded]", name);
      }
    })
  );
  if (doInit) init();
}

export async function unload(plugins: Integration | Integration[]) {
  return await Promise.all(
    Array.isArray(plugins)
      ? plugins
      : [plugins].map(async (name) => {
          logger.info("[dekigoto:integrations:unload]", name);
          const unloader = loaded[name as Integration];
          if (unloader) {
            await unloader();
            loaded[name as Integration] = undefined;
          }
        })
  );
}
