import { type ReactElement, useEffect, useState } from "react";

interface Props {
  children?: (currentTime: Date) => ReactElement | null;
  interval?: number;
  enabled?: boolean;
}

export function useCurrentTime(interval = 1000, enabled = true) {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    if (!enabled) return;

    const intervalID = setInterval(() => setCurrentTime(new Date()), interval);
    return () => {
      clearInterval(intervalID);
    };
  }, [interval, enabled]);

  return currentTime;
}

export default function TimeTicker({
  interval = 1000,
  enabled = true,
  children
}: Props) {
  const currentTime = useCurrentTime(interval, enabled);
  if (!children) return null;
  return children(currentTime);
}
