import { useMap } from "@vis.gl/react-google-maps";
import React, { type ReactNode } from "react";
import { IsConstrainedProvider } from "../../atoms";
import AbsoluteContainer from "../../atoms/AbsoluteContainer";
import ContentArea from "../../atoms/ContentArea";
import IconButton from "../../atoms/IconButton";
import Stack from "../../atoms/Stack";
import Surface from "../../atoms/Surface";
import Column from "../../quarks/Column";
import Row from "../../quarks/Row";

interface Props {
  id: string;
  topLeft?: ReactNode;
  topCenter?: ReactNode;
  topRight?: ReactNode;
  bottomLeft?: ReactNode;
  bottomCenter?: ReactNode;
  bottomRight?: ReactNode;
  minZoom: number;
  maxZoom: number;
  mapRef?: any;
}

export default function MapControls({
  topLeft,
  topCenter,
  topRight,
  bottomLeft,
  bottomCenter,
  bottomRight,
  id,
  minZoom,
  maxZoom
}: Props) {
  const map = useMap(id);
  return (
    <IsConstrainedProvider value>
      <AbsoluteContainer left={0} top={0}>
        <ContentArea horizontal fill alignItems="flex-start" gap="standard">
          <Column>{topLeft}</Column>
          <Column fill>{topCenter}</Column>
          <Column>{topRight}</Column>
        </ContentArea>
      </AbsoluteContainer>
      <AbsoluteContainer left={0} bottom={0}>
        <ContentArea fill>
          <Row fill alignItems="flex-end" gap="standard">
            <Column>{bottomLeft}</Column>
            <Column fill>{bottomCenter}</Column>
            {bottomRight}
            <Column gap="standard">
              <Surface variant="outline">
                <Stack variant="divider">
                  <IconButton
                    contentAreaSize="compact"
                    contentAreaVariant="medium"
                    variant="solid"
                    size="tiny"
                    testID="zoom-in-btn"
                    name="plus"
                    onPress={() => {
                      let newZoom = (map?.getZoom() ?? 0) + 1;
                      newZoom = newZoom > maxZoom ? maxZoom : newZoom;
                      map?.setZoom(newZoom);
                    }}
                  />
                  <IconButton
                    contentAreaSize="compact"
                    contentAreaVariant="medium"
                    variant="solid"
                    size="tiny"
                    testID="zoom-out-btn"
                    name="minus"
                    onPress={() => {
                      let newZoom = (map?.getZoom() ?? 0) - 1;
                      newZoom = newZoom < minZoom ? minZoom : newZoom;
                      map?.setZoom(newZoom);
                    }}
                  />
                </Stack>
              </Surface>
            </Column>
          </Row>
        </ContentArea>
      </AbsoluteContainer>
    </IsConstrainedProvider>
  );
}
