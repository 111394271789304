export const getScrollbarWidth = () => {
  return 0;

  // TODO: compute scrollbar width based on a scrollable element

  // const scrollDiv = document.createElement("div");
  // scrollDiv.className = "scrollbar-measure";
  // document.body.appendChild(scrollDiv);

  // // Get the scrollbar width
  // const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  // document.body.removeChild(scrollDiv);

  // return scrollbarWidth;
};
