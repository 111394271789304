import { createComponentPortal } from "@gigsmart/aperture";
import React, { type ReactNode, createContext, useContext } from "react";
import { View } from "react-native";
import { getSpaceUnits } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";

type Props = {
  children?: ReactNode;
};

const { Entrance, Realm, Exit } = createComponentPortal();

export function StepFooter({ children }: Props) {
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const stickyFooter = useContext(Context).stickyFooter;

  const styles = useStyles(
    ({ media }) => ({
      content: {
        gap: getSpaceUnits("medium"),
        marginHorizontal: "auto",
        width: "100%",
        maxWidth: 400,
        alignSelf: "center",
        paddingTop: getSpaceUnits("standard"),
        paddingBottom: getSpaceUnits("standard")
      },
      contentMd: {
        flexDirection: "row",
        justifyContent: "flex-end",
        maxWidth: media.size.large.maxWidth,
        paddingRight: getSpaceUnits("xlarge"),
        paddingBottom: getSpaceUnits("xlarge")
      },
      contentInline: {
        paddingTop: 0,
        paddingBottom: isMd ? getSpaceUnits("xlarge") : 0
      }
    }),
    [stickyFooter, isMd]
  );

  return (
    <Entrance>
      <View
        style={[
          styles.content,
          isMd && styles.contentMd,
          !stickyFooter && styles.contentInline
        ]}
      >
        {children}
      </View>
    </Entrance>
  );
}

type ProviderProps = {
  children?: ReactNode;
  stickyFooter?: boolean;
};
const Context = createContext<Omit<ProviderProps, "children">>({
  stickyFooter: false
});

StepFooter.Exit = Exit;
StepFooter.Provider = function StepFooterProvider({
  children,
  ...rest
}: ProviderProps) {
  return (
    <Context.Provider value={rest}>
      <Realm>{children}</Realm>
    </Context.Provider>
  );
};
