import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import Stack from "../../atoms/Stack";
import { useStyles } from "../../style";
import ActionSheetButton, { actionButtonSpecs } from "./ActionSheetButton";
import { ActionSheetPortalEntrance } from "./ActionSheetPortal";
import type { ActionSheetProps as Props } from "./types";

export default function ActionSheetModal({
  children,
  options,
  position,
  orientation = "downwards",
  visible,
  onClose,
  autoClose,
  size = "large"
}: Props) {
  const [left, setLeftState] = useState<number | null>(null);
  const [handleLeft, setHandleLeftState] = useState<number | null>(null);
  const [top, setTop] = useState<number | null>(null);
  const [bottom, setBottom] = useState<number>(0);
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      card: {
        display: "flex",
        backgroundColor: getColor("surface", "fill"),
        borderRadius: 4,
        height: options.length * actionButtonSpecs.height[size],
        marginHorizontal: 0,
        position: "absolute",
        flexDirection: "column",
        justifyContent: "center",
        borderColor: getColor("divider", "fill"),
        borderWidth: 1,
        zIndex: 11,
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: getUnits(0.5),
        shadowOffset: {
          width: 0,
          height: 0
        }
      },
      cardAfter: {
        zIndex: 12,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderColor: getColor("divider", "fill"),
        position: orientation === "downwards" ? "absolute" : undefined,
        width: 17,
        height: 17,
        backgroundColor: getColor("surface", "fill"),
        top: size === "large" ? -7 : -8,
        transform: [
          { rotate: orientation === "downwards" ? "45deg" : "225deg" }
        ]
      },
      invisibleCardBack: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "transparent",
        zIndex: 10,
        position: "fixed" as any,
        cursor: "default"
      },
      buttonContainer: {
        paddingTop: getUnits(3)
      },
      upwardArrow: {
        top: -6
      }
    }),
    [visible, size]
  );

  const setLeft = (initialLeft: number, layoutWidth: number) => {
    if (position === "left") {
      setLeftState(initialLeft + layoutWidth / 2 - 32);
      setHandleLeftState(26);
      return;
    }
    if (position === "center") {
      setLeftState(
        initialLeft - actionButtonSpecs.minWidth[size] / 2 + layoutWidth / 2
      );
      setHandleLeftState(actionButtonSpecs.minWidth[size] / 2 - 8);
      return;
    }
    if (position === "right") {
      setLeftState(initialLeft);
      setLeftState(
        initialLeft - actionButtonSpecs.minWidth[size] + layoutWidth / 2 + 32
      );
      setHandleLeftState(actionButtonSpecs.minWidth[size] - 40);
    }
  };
  if (!visible) return <View>{children}</View>;
  return (
    <View>
      {children}
      <View
        onLayout={(e) => {
          if (!e.nativeEvent) return;
          // @ts-expect-error: wrong nativeEvent definition
          const rect = e.nativeEvent.target.getBoundingClientRect();
          const top =
            (Number(rect.y) ?? 0) + (Number(window.scrollY) ?? 0) + 12;
          setTop(top);
          setLeft(rect.x, e.nativeEvent.layout.width);
          setBottom(top - 60 - (size === "large" ? 52 : 35) * options.length);
        }}
      />
      {visible && (
        <ActionSheetPortalEntrance
          actionSheet={() => {
            if (top === null || left === 0) return null;
            return (
              <>
                <TouchableOpacity
                  style={styles.invisibleCardBack}
                  onPress={onClose}
                />
                <View
                  style={[
                    styles.card,
                    {
                      left: left ?? 0,
                      top: orientation === "downwards" ? top : bottom
                    }
                  ]}
                  testID="action-sheet-modal"
                >
                  <View>
                    {orientation === "downwards" && (
                      <View
                        style={[
                          styles.cardAfter,
                          {
                            left: handleLeft ?? 0
                          }
                        ]}
                      />
                    )}
                    <Stack
                      variant="divider"
                      style={
                        orientation === "upwards" && styles.buttonContainer
                      }
                    >
                      {options?.map(
                        ({
                          label,
                          testID,
                          onPress,
                          destructive,
                          icon,
                          disabled
                        }) => (
                          <ActionSheetButton
                            key={label}
                            size={size}
                            label={label}
                            testID={testID}
                            onPress={() => {
                              onPress?.();
                              if (autoClose && onClose) onClose();
                            }}
                            destructive={destructive}
                            icon={icon}
                            disabled={disabled}
                          />
                        )
                      )}
                    </Stack>
                    {orientation === "upwards" && (
                      <View
                        style={[
                          styles.cardAfter,
                          styles.upwardArrow,
                          {
                            left: handleLeft ?? 0
                          }
                        ]}
                      />
                    )}
                  </View>
                </View>
              </>
            );
          }}
        />
      )}
    </View>
  );
}
