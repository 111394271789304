import { useEffect, useState } from "react";
import {
  Keyboard,
  type KeyboardEvent,
  LayoutAnimation,
  Platform
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type KeyboardStateOptions = {
  enabled?: boolean;
  extraSpace?: number;
};
export function useKeyboardState({
  enabled = true,
  extraSpace = 0
}: KeyboardStateOptions = {}) {
  const [bottom, setBottom] = useState(0);
  const bottomInset = useSafeAreaInsets().bottom;
  if (!extraSpace) extraSpace = -Math.max(bottomInset - 16, extraSpace);

  if (Platform.OS !== "ios") enabled = false;

  useEffect(() => {
    if (Platform.OS !== "ios" || !enabled) return;

    const prepareAnimation = (e: KeyboardEvent) => {
      const animType = LayoutAnimation.Types[e.easing];
      if (animType && e.duration) {
        try {
          LayoutAnimation.configureNext(
            LayoutAnimation.create(e.duration, animType)
          );
        } catch (err) {
          console.warn(err);
        }
      }
    };
    const handleShow = (e: KeyboardEvent) => {
      prepareAnimation(e);
      const keyboardHeight = e.endCoordinates.height;
      setBottom(keyboardHeight + extraSpace);
    };

    const handleHide = (e: KeyboardEvent) => {
      prepareAnimation(e);
      setBottom(0);
    };

    const listeners = [
      Keyboard.addListener("keyboardWillShow", handleShow),
      Keyboard.addListener("keyboardWillHide", handleHide)
    ];
    return () => {
      listeners.forEach((emitter) => emitter.remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, extraSpace]);

  return { bottom };
}
