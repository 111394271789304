import React, { type JSXElementConstructor } from "react";

export const typedMemo: <
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
>(
  c: T,
  areEqual?: (
    prev: React.ComponentProps<T>,
    next: React.ComponentProps<T>
  ) => boolean
) => T = React.memo;

export function inspect<T>(x: T, label?: string): T {
  label ? console.debug("🔧", label, x) : console.debug("🔧", x);
  return x;
}
