import React from "react";
import { Platform, TextInput, type TextInputProps, View } from "react-native";
import IconButton from "../atoms/IconButton";
import Icon from "../quarks/Icon";
import { useStyles } from "../style";
import colors from "../style/theme/colors";
import { fontWeights } from "../style/theme/fontHelpers";

interface Props
  extends Pick<TextInputProps, "value" | "placeholder" | "onChangeText"> {
  testID: string;
  backgroundColor?: "surface" | "foreground";
}

export default function SearchInput({
  value,
  onChangeText,
  placeholder,
  testID,
  backgroundColor = "surface"
}: Props) {
  const styles = useStyles(({ getColor, getUnits, getFontSize, fonts }) => ({
    container: {
      backgroundColor: getColor(backgroundColor, "fill"),
      borderRadius: getUnits(1),
      flexDirection: "row",
      paddingHorizontal: getUnits(2),
      alignItems: "center",
      height: getUnits(11),
      flex: 1
    },
    input: {
      flex: 1,
      height: getUnits(8),
      padding: getUnits(2),
      color: getColor("textPrimary", "fill"),
      ...Platform.select({
        web: { outlineStyle: "none" }
      }),
      ...getFontSize(),
      fontFamily: fonts.sansSerif,
      fontWeight: fontWeights.normal
    }
  }));
  return (
    <View style={styles.container} testID={testID}>
      <Icon name="search" size="tiny" color="neutral" variant="solid" />
      <TextInput
        style={styles.input}
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder}
        placeholderTextColor={colors.input.placement}
      />
      {!!value && (
        <IconButton
          testID={`${testID}-clear-btn`}
          name="times-circle"
          size="small"
          color="neutral"
          variant="solid"
          onPress={() => onChangeText?.("")}
        />
      )}
    </View>
  );
}
