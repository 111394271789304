import { debounce, throttle } from "lodash";
import { type DependencyList, useEffect, useMemo, useState } from "react";

const makeCallbackHook = <T extends (...args: any) => any>(
  specialFn: typeof debounce | typeof throttle
) => {
  return function useSpecialCallback(fn: T, ms = 300, deps: any[] = []) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const newFn = useMemo(() => (ms === 0 ? fn : specialFn(fn, ms)), deps);

    // cancel and flush the cache after
    useEffect(() => {
      return () => {
        if ("cancel" in newFn) newFn.cancel();
      };
    }, [newFn]);

    return newFn as T;
  };
};

export const useDebouncedCallback = makeCallbackHook(debounce);
export const useThrottledCallback = makeCallbackHook(throttle);
export function usePromisedValue<T>(
  fn: () => Promise<T>,
  defaultValue: T,
  deps: DependencyList = []
): T {
  const [value, setValue] = useState<T>(defaultValue);
  useEffect(() => {
    void fn().then(setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return value;
}

export const useDebouncedState = <T = any>(
  defaultValue: T,
  delay = 100
): [T, (value: T) => void] => {
  const [value, setValue] = useState<T>(defaultValue);
  const setDebouncedValue = useDebouncedCallback(setValue, delay);
  return [value, setDebouncedValue];
};

export const useThrottledState = <T = any>(
  defaultValue: T,
  delay = 100
): [T, (value: T) => void] => {
  const [value, setValue] = useState<T>(defaultValue);
  const setDebouncedValue = useThrottledCallback(setValue, delay);
  return [value, setDebouncedValue];
};
