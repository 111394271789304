import React, { type ComponentProps } from "react";
import { View } from "react-native";
import { Marker } from "react-native-maps";
import Avatar from "../atoms/Avatar";
import { useStyles } from "../style";

interface Props {
  active?: boolean;
  latitude: number;
  longitude: number;
  avatarUri: string;
  onPress?: () => void;
  testID: string;
  zIndex?: ComponentProps<typeof Marker>["zIndex"];
  tracksViewChanges?: ComponentProps<typeof Marker>["tracksViewChanges"];
}

export default function MapAvatarMarker({
  active,
  latitude,
  longitude,
  avatarUri,
  onPress,
  testID,
  zIndex,
  tracksViewChanges
}: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      border: {
        borderColor: getColor(active ? "success" : "surface", "fill"),
        borderWidth: 4,
        borderRadius: 20
      },
      borderAfter: {
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: 4,
        borderRightWidth: 4,
        borderTopWidth: 8,
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderTopColor: getColor(active ? "success" : "surface", "fill"),
        position: "absolute",
        top: 34,
        left: 14
      }
    }),
    [active]
  );
  return (
    <Marker
      coordinate={{
        latitude: latitude ?? 0,
        longitude: longitude ?? 0
      }}
      anchor={{ x: 0.5, y: 0.5 }}
      onPress={onPress}
      zIndex={zIndex}
      tracksViewChanges={tracksViewChanges}
    >
      <View style={{ overflow: "visible", height: 80 }}>
        <View style={styles.border} testID={testID}>
          <Avatar uri={avatarUri} size="small" />
        </View>
        <View style={styles.borderAfter} />
      </View>
    </Marker>
  );
}
