import React, {
  type ComponentProps,
  type ComponentType,
  useState
} from "react";
import { View, useWindowDimensions } from "react-native";
import Swiper from "react-native-web-swiper";
import Stack from "../atoms/Stack";
import Text from "../quarks/Text";
import { useMatchesViewport, useStyles } from "../style";

interface SwipeData {
  title: string;
  subtitle: string;
  content: ComponentType<{}>;
}
interface Props extends ComponentProps<typeof Swiper> {
  data: SwipeData[];
  maxHeight?: number;
}

export default function SwipeCarousel({
  data,
  timeout = 7,
  maxHeight = 500,
  onIndexChanged,
  ...rest
}: Props) {
  const isLg = useMatchesViewport(({ size }) => size.large.up);
  const isMd = useMatchesViewport(({ size }) => size.medium.down);
  const isXs = useMatchesViewport({ maxWidth: 480 });
  const isXXs = useMatchesViewport({ maxWidth: 380 });
  const { width } = useWindowDimensions();
  const [activeIndex, setActiveIndex] = useState(0);

  const styles = useStyles(
    ({ getFontSize, getUnits, getColor }) => ({
      container: {
        flex: 1,
        maxHeight
      },
      carouselItem: {
        flex: 1,
        ...(isLg && { maxWidth: getUnits(100), alignItems: "center" }),
        ...(isMd && {
          maxWidth: getUnits(91),
          flexDirection: "column-reverse"
        })
      },
      contentContainer: {
        flex: 1,
        alignSelf: "center",
        ...(isMd && { marginBottom: getUnits(8) }),
        ...(isXs && { width: width * 0.75 }),
        ...(isXXs && { marginBottom: getUnits(20) })
      },
      textContainer: {
        justifyContent: "center",
        marginBottom: getUnits(6),
        ...(isLg && { marginBottom: getUnits(8), marginTop: getUnits(10) })
      },
      title: {
        marginBottom: getUnits(3),
        ...getFontSize(5),
        ...(isLg && { ...getFontSize(6) })
      },
      subtitleContainer: {
        ...(isXs && {
          width,
          alignSelf: "center",
          paddingHorizontal: 16
        })
      },
      subtitle: {
        ...getFontSize(3.5)
      },
      indicatorContainer: {
        flexDirection: "row",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
        ...(isXXs && { bottom: getUnits(12) })
      },
      dot: {
        width: getUnits(2),
        height: getUnits(2),
        borderWidth: getUnits(0.25),
        borderRadius: getUnits(1),
        borderColor: getColor("primary", "fill")
      },
      dotFilled: {
        backgroundColor: getColor("primary", "fill")
      },
      overrideSlideWrapper: { flexDirection: "row", justifyContent: "center" }
    }),
    [isMd, isLg, isXs]
  );

  const SwipeIndicator = () => {
    return (
      <View style={styles.indicatorContainer}>
        <Stack horizontal justifyContent="center">
          {data.map((_, index) => (
            <View
              key={`swiper-indicator-${index}`}
              style={[
                styles.dot,
                ...(index === activeIndex ? [styles.dotFilled] : [])
              ]}
            />
          ))}
        </Stack>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Swiper
        key={`swiper-${width}`}
        timeout={timeout}
        onIndexChanged={(index) => {
          setActiveIndex(index);
          onIndexChanged?.(index);
        }}
        Controls={() => <SwipeIndicator />}
        slideWrapperStyle={styles.overrideSlideWrapper}
        {...rest}
      >
        {data.map(({ title, subtitle, content: Content }, index) => {
          return (
            <View key={`${title}-${index}`} style={styles.carouselItem}>
              <View style={styles.contentContainer}>
                <Content />
              </View>
              <View style={styles.textContainer}>
                <Text
                  style={styles.title}
                  color="primary"
                  weight="bold"
                  align="center"
                >
                  {title}
                </Text>
                <View style={styles.subtitleContainer}>
                  <Text style={styles.subtitle} color="primary" align="center">
                    {subtitle}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
      </Swiper>
    </View>
  );
}
