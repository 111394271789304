import { definePermission } from "../registry";

export default definePermission({
  name: "Location",
  icon: "map-marked",
  defaults: {
    preview: true,
    reason: "We need your location to provide you with the best experience"
  }
});
