import type { DateTime } from "luxon";
import React, { useMemo } from "react";
import { View } from "react-native";

import { Text } from "../../quarks";
import { useStyles } from "../../style";
import Day from "./Day";
import useCalendar from "./useCalendar";

interface Props {
  testID?: string;
  min?: DateTime;
  max?: DateTime;
  month: DateTime;
  selectedDays?: DateTime[] | null;
  onChange?: (value: DateTime[]) => void;
  multiple?: boolean;
  pinnedDays?: DateTime[];
  constrained?: boolean;
}

const dayKey = (d: DateTime) => `${d.year}-${d.month}-${d.day}`;

export default function DaysSelector({
  testID,
  month,
  min,
  max,
  selectedDays,
  pinnedDays,
  onChange,
  multiple,
  constrained = true
}: Props) {
  const weeks = useCalendar(month);
  const { selectedHash, pinnedHash } = useMemo(
    () => ({
      selectedHash: new Set(selectedDays?.map((it) => dayKey(it)) ?? []),
      pinnedHash: new Set(pinnedDays?.map((it) => dayKey(it)) ?? [])
    }),
    [selectedDays, pinnedDays]
  );

  const styles = useStyles(() => ({
    header: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    week: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginVertical: 1
    },
    weekHeader: {
      textAlign: "center"
    },
    container: {
      alignItems: "center"
    },
    content: [
      {
        maxWidth: constrained ? 400 : undefined,
        width: "100%"
      }
    ]
  }));

  return (
    <View style={styles.container} testID={testID}>
      <View style={styles.content}>
        <View style={styles.week}>
          {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
            <Text
              variant="note"
              weight="bold"
              color="neutral"
              key={`${day}-${index}`}
            >
              {day}
            </Text>
          ))}
        </View>
        {weeks.map((week) => (
          <View key={`week-${week[0]?.weekNumber}`} style={styles.week}>
            {week.map((day) => {
              const isDisabled = (!!min && min > day) || (!!max && max < day);
              const key = dayKey(day);
              const isSelected = selectedHash.has(key);
              const isPinned = pinnedHash.has(key);

              return (
                <Day
                  key={key}
                  label={`${day.day}`}
                  testID={`date-${day.day}`}
                  isSelected={isSelected}
                  isSameMonth={day.month === month.month}
                  isPinned={isPinned}
                  disabled={(isDisabled && !isSelected) || !onChange}
                  onPress={() => {
                    if (multiple) {
                      const idx =
                        selectedDays?.findIndex((it) => dayKey(it) === key) ??
                        0;
                      const newValue = selectedDays ? [...selectedDays] : [];

                      if (isSelected) newValue.splice(idx, 1);
                      else newValue.splice(idx, 0, day);
                      onChange?.(newValue);
                    } else if (!isSelected) {
                      onChange?.([day]);
                    }
                  }}
                />
              );
            })}
          </View>
        ))}
      </View>
    </View>
  );
}
