import { useCallback, useState } from "react";
import type { GraphQLTaggedNode, OperationType } from "relay-runtime";
import { useRelayOrchestrator } from "../orchestrator";
import { refetchOnly } from "../query";

import type { KeyType } from "../fragment";
import { useRelayRefetchableFragment } from "./use-relay-refetchable-fragments";

export function useRefetchOnly<
  Q extends OperationType,
  TKey extends KeyType<{ readonly id: string }>
>(fragmentInput: GraphQLTaggedNode, fragmentRef: TKey | null) {
  const { fetchQuery } = useRelayOrchestrator();
  const [loading, setLoading] = useState(false);
  const [data, refetch] = useRelayRefetchableFragment<Q, TKey>(
    fragmentInput,
    fragmentRef
  );

  const handleRefetch = useCallback(
    async (vars: Partial<Q["variables"]> = {}) => {
      try {
        setLoading(true);
        await refetchOnly<Q>(
          refetch,
          fetchQuery,
          fragmentRef,
          fragmentInput,
          data?.id,
          vars
        );
      } finally {
        setLoading(false);
      }
    },
    [data?.id, fetchQuery, fragmentInput, fragmentRef, refetch]
  );

  return [data, { refetch: handleRefetch, loading }] as const;
}
