import { AdvancedMarker } from "@vis.gl/react-google-maps";
import React from "react";

interface Props {
  center: { lat: number; lng: number };
  draggable?: boolean;
  onDragEnd?: (lat: number, lng: number) => void;
  onDragStart?: (lat: number, lng: number) => void;
  onDrag?: (lat: number, lng: number) => void;
  children?: React.ReactNode;
}

export default function MapMarker({
  center,
  draggable,
  onDragEnd,
  onDrag,
  onDragStart,
  children
}: Props) {
  const handleDrag = (e: any) => {
    onDrag?.(e.latLng?.lat() ?? 0, e.latLng?.lng() ?? 0);
  };
  const handleDragStart = (e: any) => {
    onDragStart?.(e.latLng?.lat() ?? 0, e.latLng?.lng() ?? 0);
  };
  const handleDragEnd = (e: any) => {
    onDragEnd?.(e.latLng?.lat() ?? 0, e.latLng?.lng() ?? 0);
  };
  return (
    <AdvancedMarker
      position={{
        lat: center?.lat ?? 0,
        lng: center?.lng ?? 0
      }}
      draggable={draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDrag={handleDrag}
      children={children}
    />
  );
}
