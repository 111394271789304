import { createComponentPortal } from "@gigsmart/aperture";
import React, { type ReactNode, createContext, useContext } from "react";
import { View } from "react-native";
import { useStickyFooter } from "../../atoms/FooterContent";
import { getSpaceUnits } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import { getScrollbarWidth } from "../../utils/measurements";

type Props = {
  children?: ReactNode;
};

const { Entrance, Realm, Exit } = createComponentPortal();

export function StepFooter({ children }: Props) {
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const stickyFooter = useContext(Context).stickyFooter;
  const { bottom, contentSize, onLayout } = useStickyFooter({
    enabled: stickyFooter
  });

  const styles = useStyles(
    ({ getUnits, media, getColor }) => ({
      abs: {
        position: "absolute",
        left: 0,
        right: 0,
        paddingLeft: getUnits(4),
        paddingRight: getUnits(4) + getScrollbarWidth(),
        backgroundColor: isMd ? "transparent" : getColor("surface", "fill")
      },
      inline: {
        marginTop: "auto"
      },
      content: {
        gap: getSpaceUnits("medium"),
        marginHorizontal: "auto",
        width: "100%",
        maxWidth: 400,
        alignSelf: "center",
        paddingTop: getSpaceUnits("standard"),
        paddingBottom: getSpaceUnits("standard")
      },
      contentMd: {
        flexDirection: "row",
        justifyContent: "flex-end",
        maxWidth: media.size.large.maxWidth,
        paddingRight: getSpaceUnits("xlarge"),
        paddingBottom: getSpaceUnits("xlarge")
      },
      contentMdInline: {
        paddingTop: 0,
        paddingBottom: getSpaceUnits("xlarge")
      }
    }),
    [stickyFooter, isMd]
  );

  const content = (
    <View
      style={[
        styles.content,
        isMd && styles.contentMd,
        !stickyFooter && styles.contentMdInline
      ]}
      onLayout={onLayout}
    >
      {children}
    </View>
  );

  return (
    <Entrance>
      {!!stickyFooter && <View style={{ height: contentSize }} />}
      <View style={stickyFooter ? [styles.abs, { bottom }] : styles.inline}>
        {content}
      </View>
    </Entrance>
  );
}

type ProviderProps = {
  children?: ReactNode;
  stickyFooter?: boolean;
};
const Context = createContext<Omit<ProviderProps, "children">>({
  stickyFooter: false
});

StepFooter.Exit = Exit;
StepFooter.Provider = function StepFooterProvider({
  children,
  ...rest
}: ProviderProps) {
  return (
    <Context.Provider value={rest}>
      <Realm>{children}</Realm>
    </Context.Provider>
  );
};
