import type {
  CacheConfig,
  FetchQueryFetchPolicy,
  GraphQLTaggedNode,
  OperationType
} from "relay-runtime";
import type { RelayOrchestratorProp } from "../orchestrator";
import type { PageInfoType } from "./types";

export interface FetchConnectionOptions<TQuery extends OperationType, TNode> {
  querySpec: GraphQLTaggedNode;
  variables: TQuery["variables"];
  getConnectionNodes: (res: TQuery["response"]) => TNode[];
  getPageInfo?: (res: TQuery["response"]) => PageInfoType | null | undefined;
}

export async function fetchConnectionNodes<
  TQuery extends OperationType = never,
  TNode = never
>(
  fetchQuery: RelayOrchestratorProp["fetchQuery"],
  {
    querySpec,
    variables,
    getConnectionNodes,
    getPageInfo
  }: FetchConnectionOptions<TQuery, TNode>,
  options?: {
    fetchPolicy?: FetchQueryFetchPolicy;
    networkCacheConfig?: CacheConfig;
  }
) {
  let nodes: TNode[] = [];
  let hasNextPage = true;
  let after: string | null | undefined =
    "after" in variables ? variables.after : undefined;

  do {
    const res = await fetchQuery<TQuery>(
      querySpec,
      { ...variables, after },
      options
    );
    const pageInfo = getPageInfo?.(res);
    nodes = nodes.concat(getConnectionNodes(res));
    after = pageInfo?.endCursor;
    hasNextPage = !!after && !!pageInfo?.hasNextPage;
  } while (hasNextPage);

  return nodes;
}
