import React, { type ReactNode } from "react";
import { View } from "react-native";
import { ContentArea, FooterSpacer, Surface } from "../../atoms";
import { ScreenScroll } from "../../layout";
import type { StepperLayoutProps } from "../../layout/Stepper";
import type { StepConfig } from "../../layout/Stepper/Stepper.types";
import { Divider, Row, Spacer } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import { useThrottledState } from "../../utils";
import { StepFooter } from "./StepFooter";
import { StepHeader } from "./StepHeader";
import { StepIndicatorLabel } from "./StepIndicator";

type OverrideProps = {
  testID: string;
  stickyFooter?: "never" | "always" | "auto";
};

type Props = StepperLayoutProps<OverrideProps> & { header?: JSX.Element };

export function StepWithIndicators({
  header: stepperHeader,
  current,
  steps,
  children
}: Props) {
  return (
    <StepWithIndicatorsLayout
      header={stepperHeader}
      testID={current.options.testID}
      stickyFooter={current.options.stickyFooter}
      horizontalIndicator={
        <HorizontalIndicator steps={steps} current={current.index} />
      }
      verticalIndicator={
        <VerticalIndicator steps={steps} current={current.index} />
      }
    >
      {children}
    </StepWithIndicatorsLayout>
  );
}

type StepWithIndicatorsLayoutProps = {
  testID: string;
  header?: ReactNode;
  stickyFooter?: "never" | "always" | "auto";
  children?: ReactNode;

  /// only used by steppers
  horizontalIndicator?: JSX.Element;
  verticalIndicator?: JSX.Element;
};

export function StepWithIndicatorsLayout({
  header,
  testID,
  stickyFooter = "auto",
  horizontalIndicator,
  verticalIndicator,
  children
}: StepWithIndicatorsLayoutProps) {
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const [contentHeight, setContentHeight] = useThrottledState(0);
  const [height, setHeight] = useThrottledState(0);

  const shouldUseStickyFooter =
    !isMd ||
    stickyFooter === "always" ||
    (stickyFooter === "auto" && contentHeight > height);

  return (
    <StepHeader.Realm>
      <StepFooter.Provider stickyFooter={shouldUseStickyFooter}>
        <ScreenScroll
          testID={testID}
          automaticallyAdjustContentInsets={false}
          keyboardDismissMode="none"
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          color={isMd ? "background" : "surface"}
          onContentSizeChange={(_, h) => setContentHeight(h)}
          onLayout={(e) => setHeight(e.nativeEvent.layout.height)}
          footer={shouldUseStickyFooter && <StepFooter.Exit />}
        >
          {header ?? <StepHeader.Exit />}
          {!isMd && horizontalIndicator}

          {verticalIndicator ? (
            <Row gap="large">
              {isMd && verticalIndicator}
              <Surface
                fill
                variant={isMd ? "shadow" : "flat"}
                testID={`${testID}-content`}
              >
                {children}
                {!shouldUseStickyFooter && <StepFooter.Exit />}
              </Surface>
            </Row>
          ) : (
            <Surface
              variant={isMd ? "shadow" : "flat"}
              testID={`${testID}-content`}
            >
              {children}
              {!shouldUseStickyFooter && <StepFooter.Exit />}
            </Surface>
          )}
          <FooterSpacer />
        </ScreenScroll>
      </StepFooter.Provider>
    </StepHeader.Realm>
  );
}

type IndicatorProps = {
  steps: StepConfig<unknown, unknown>[];
  current: number;
};

function VerticalIndicator({ steps, current }: IndicatorProps) {
  return (
    <ContentArea>
      {steps.map((step, index) => (
        <StepIndicatorLabel
          key={step.name}
          label={step.name}
          num={index + 1}
          first={index === 0}
          active={step.index <= current}
        />
      ))}
    </ContentArea>
  );
}

function HorizontalIndicator({ steps, current }: IndicatorProps) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      paddingVertical: getUnits(5)
    },
    connector: {
      flex: 1,
      height: getUnits(2),
      backgroundColor: getColor("inactiveStep", "fill")
    },
    active: {
      backgroundColor: getColor("primary", "fill")
    }
  }));

  return (
    <ContentArea size="large" constraint="xsmall" constrainedVariant="none">
      <Row gap={2}>
        {steps.map((step) => (
          <View
            style={[styles.connector, step.index <= current && styles.active]}
          />
        ))}
      </Row>
      <Spacer size="large" />
      <Divider />
    </ContentArea>
  );
}
