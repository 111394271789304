import React, { createContext, useContext } from "react";
import { Platform, type ViewStyle } from "react-native";
import { Column, type UnitSize } from "../quarks";
import {
  type UseTransformFlexProps,
  useDiscriminatedStyle,
  usePlacementColor,
  useStyle
} from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";
import type { Color } from "../style/theme/colors";
import { useIsConstrained } from "./Constraint";
import { computeContentRadius } from "./ContentArea";

type Props = Pick<
  UseTransformFlexProps,
  "fill" | "grow" | "gap" | "rowGap" | "columnGap" | "children"
> & {
  testID?: string;
  zIndex?: number;
  color?: Color;
  variant?: "shadow" | "flat" | "outline" | "mixed";
  fade?: boolean;
  hideInnerMargin?: boolean;
  overflow?: ViewStyle["overflow"];
  topRadius?: UnitSize | "auto";
  bottomRadius?: UnitSize | "auto";
};

const InSurfaceContext = createContext(false);
const useInSurfaceContext = () => useContext(InSurfaceContext);
const InSurfaceProvider = InSurfaceContext.Provider;

export default function Surface({
  color,
  fade,
  variant = "shadow",
  hideInnerMargin,
  overflow,
  topRadius = "auto",
  bottomRadius = "auto",
  zIndex,
  ...rest
}: Props) {
  const bg = usePlacementColor();
  const isConstrained = useIsConstrained();
  const inSurface = useInSurfaceContext();

  if (variant === "mixed") {
    variant = bg.name === "surface" ? "outline" : "shadow";
  }

  const baseStyle = useStyle(() => {
    const isRounded = isConstrained || variant !== "shadow";
    return {
      overflow:
        overflow ??
        (inSurface && Platform.OS === "android" ? "hidden" : undefined),
      breakInside: "avoid",
      ...computeContentRadius(topRadius, bottomRadius, isRounded)
    };
  }, [overflow, color, bottomRadius, topRadius, isConstrained]);

  const variantStyle = useDiscriminatedStyle(
    variant,
    ({ getColor, getUnits }) => {
      const marginBottom = hideInnerMargin ? getUnits(0) : getUnits(1);
      const primaryColor = getColor(color ?? "surface", "fill", {
        opacity: fade ? 0.08 : undefined
      });
      return {
        shadow: {
          backgroundColor: primaryColor,
          shadowColor: "#000000",
          shadowOpacity: 0.15,
          shadowOffset: { width: 0, height: 2 },
          marginBottom,
          ...Platform.select({
            // add a little padding at the bottom to make shadows visible
            default: { overflow, shadowRadius: 4 },
            android: {
              overflow: inSurface ? "hidden" : overflow,
              elevation: zIndex ?? 2
            }
          })
        },
        flat: {
          backgroundColor: primaryColor
        },
        outline: {
          backgroundColor: primaryColor,
          borderWidth: 1,
          borderColor: getColor(color ?? "divider", "fill"),
          marginTop:
            inSurface && isConstrained && variant === "outline" ? -1 : 0,
          marginLeft:
            inSurface && isConstrained && variant === "outline" ? -1 : 0,
          marginRight:
            inSurface && isConstrained && variant === "outline" ? -1 : 0
        }
      };
    },
    [color, variant, hideInnerMargin, inSurface]
  );

  return (
    <InSurfaceProvider value>
      <ColorPlacementProvider color={fade || !color ? "surface" : color}>
        <Column {...rest} zIndex={zIndex} style={[baseStyle, variantStyle]} />
      </ColorPlacementProvider>
    </InSurfaceProvider>
  );
}
