import { once } from "lodash";
import { Duration, type DurationLikeObject } from "luxon";
// @refresh reset
import React from "react";
import type { ReactNode } from "react";
import { AppState, Platform } from "react-native";
import type { IconName } from "../../quarks";
import { Persistence } from "../../utils";
import { type AnyModal, type ModalAction, showModal } from "../ModalProvider";
import PermissionsRequestModal from "./PermissionRequestModal";
import { logger as baseLogger } from "./helpers";

export type PermissionStatus =
  | "unavailable"
  | "denied"
  | "limited"
  | "granted"
  | "blocked";

export type DetailedPermissionResponse = {
  status: PermissionStatus;
  delayed?: boolean;
  dismissed?: boolean;
  trace?: string;
};

export interface PermissionRequestOptions {
  trace?: string;
  reason: string | ReactNode;
  preview: boolean;
  required?: boolean;
  allowLimited?: boolean;
  forceAsk?: boolean;
  onDismiss?: (status: PermissionStatus) => void;
}

interface ConfigOptions {
  name: string;
  icon: IconName;
  askEvery?: DurationLikeObject;
  defaults: Omit<PermissionRequestOptions, "required" | "onDismiss">;
  restrictRequired?: boolean;
}

const IS_TESTING = process.env.IS_TESTING === "true";

interface RegisteredImplementation {
  check: (
    options?: Partial<PermissionRequestOptions>
  ) => Promise<DetailedPermissionResponse>;
  request: (
    options?: Partial<PermissionRequestOptions>
  ) => Promise<DetailedPermissionResponse>;
  addListener: (
    listener: PermissionListener,
    options: { invokeOnce?: boolean; trace?: string }
  ) => {
    remove: () => void;
  };
  logger: ReturnType<typeof baseLogger.createLogger>;
}

export type PermissionListener = (options: {
  prevResponse: DetailedPermissionResponse;
  response: DetailedPermissionResponse;
}) => void;

type InvokedPermissionListener = (status: DetailedPermissionResponse) => void;

interface Implementation {
  requestOptions: PermissionRequestOptions;
  register: SetImplFn;
  unavailable: () => RegisteredImplementation;
}

export type SetImplFn = (fns: {
  check: () => Promise<PermissionStatus>;
  request: (options: PermissionRequestOptions) => Promise<PermissionStatus>;
  openSettings: (() => void) | false;
}) => RegisteredImplementation;

const unavailableImpl = async (): Promise<PermissionStatus> => "unavailable";

export function definePermission({
  name,
  icon,
  askEvery = { weeks: 1 },
  restrictRequired,
  defaults: { reason, preview = false, allowLimited }
}: ConfigOptions): Implementation {
  const maxAge = Duration.fromObject(askEvery).toMillis();
  const permissionLogger = baseLogger.createLogger("!🎛️", "registry", name);
  let updateModal = (spec: Partial<AnyModal>) => {
    if (spec.visible === false) return;
    initModal(spec);
  };

  const initModal = once((spec: Partial<AnyModal>) => {
    showModal({
      eventContext: `${name} Permission Modal`,
      title: "",
      variant: "full",
      visible: false,
      exclusive: true,
      onInit: (updater) =>
        (updateModal = permissionLogger.wrap(updater, "updateModal")),
      onRequestClose: () => updateModal?.({ visible: false }),
      children: (
        <PermissionsRequestModal icon={icon} name={name} reason={reason} />
      ),
      dismissable: true,
      __UNSAFE_persist: true,
      ...spec
    });
  });

  const defaultRequestOptions: PermissionRequestOptions = {
    reason,
    required: false,
    preview,
    allowLimited: allowLimited ?? true
  };

  let checkFn: () => Promise<PermissionStatus> = permissionLogger.wrap(
    unavailableImpl,
    "unavailableCheck"
  );

  const delayKey = `permission:${name}:isDelayed`;

  const checkFnWithDelay = permissionLogger.wrap(
    async (trace?: string): Promise<DetailedPermissionResponse> => {
      let status = await checkFn();
      if (IS_TESTING) status = "granted";
      const isDelayed = await Persistence.load<boolean>(delayKey, {
        maxAge
      });
      return {
        status,
        delayed: !!isDelayed,
        trace: trace && `${trace}.checkFnWithDelay`
      };
    },
    "checkFnWithDelay"
  );

  let requestFn: (
    options: PermissionRequestOptions
  ) => Promise<PermissionStatus> = permissionLogger.wrap(
    unavailableImpl,
    "unavailableRequest"
  );

  let openSettingsFn: (() => void) | false = () => {};

  const optionsWithDefaults = (
    options: Partial<PermissionRequestOptions> | undefined,
    request: boolean
  ): PermissionRequestOptions & { request: boolean } => {
    const merged = Object.keys(
      defaultRequestOptions
    ).reduce<PermissionRequestOptions>(
      (acc, key) => ({
        ...acc,
        [key]:
          options?.[key as keyof PermissionRequestOptions] ??
          defaultRequestOptions[key as keyof PermissionRequestOptions]
      }),
      { ...options, ...defaultRequestOptions }
    );

    return {
      ...merged,
      preview: Platform.OS === "web" ? false : merged?.preview,
      required: merged.required && !restrictRequired,
      request
    };
  };

  const listeners: Map<number, InvokedPermissionListener> = new Map();

  const notifyChange = permissionLogger.wrap(function notifyChange({
    status,
    dismissed = false,
    delayed = false,
    trace
  }: DetailedPermissionResponse) {
    const via = trace ? ` (via: ${trace}) ` : "";
    const listenersToCall = [...listeners.values()];
    permissionLogger.info(`notifyChange${via}`, {
      listeners: listenersToCall.length
    });
    listenersToCall.forEach((listener, key) => {
      permissionLogger.info("notifyChange", `call listener: ${key}${via}`);
      listener({ status, delayed, dismissed, trace });
    });
  });

  let blockAppStateListener = false;
  AppState.addEventListener(
    "change",
    permissionLogger.wrap(function AppStateListener(state) {
      if (state === "active" && !blockAppStateListener) {
        void checkFnWithDelay("appStateChange").then(notifyChange);
      }
    }, "appStateChange")
  );

  let listenerIndex = 0;
  let prevResponse: DetailedPermissionResponse = { status: "unavailable" };
  const performAddListener = permissionLogger.wrap(function addListener(
    listener: PermissionListener,
    { invokeOnce = false, trace }: { invokeOnce?: boolean; trace?: string } = {}
  ) {
    const currentListenerIndex = listenerIndex++;

    const via = trace ? ` (via: ${trace}) ` : "";
    const invokeOnceTag = invokeOnce ? " [once] " : "";

    // Wrap listener
    const wrappedListener = permissionLogger.wrap(
      (response: DetailedPermissionResponse) => {
        if (prevResponse.status === "blocked" && response.status === "denied") {
          response.status = prevResponse.status;
        }
        listener({ prevResponse, response });
        prevResponse = response;
      },
      `invokeListener (listener: ${currentListenerIndex})${invokeOnceTag}${via}`
    );

    // Remove listener
    const removeListener = permissionLogger.wrap(
      () => listeners.delete(currentListenerIndex),
      `removeListener (listener: ${currentListenerIndex})${invokeOnceTag}${via}`
    );

    // Wrap in once
    const maybeOnceWrapper = invokeOnce
      ? once(
          permissionLogger.wrap((response: DetailedPermissionResponse) => {
            removeListener();
            wrappedListener(response);
          }, `invokeListenerOnce (listener: ${currentListenerIndex})${invokeOnceTag}${via}`)
        )
      : wrappedListener;

    // Invoke Once if possible
    if (!invokeOnce) {
      checkFnWithDelay(trace).then(
        permissionLogger.wrap(
          maybeOnceWrapper,
          `notifyChangeOnAdd (listener: ${currentListenerIndex})${via}`
        )
      );
    }

    // Add the listener
    permissionLogger.info(
      "addListener",
      `(listener: ${currentListenerIndex})${invokeOnceTag}${via}`
    );
    listeners.set(currentListenerIndex, maybeOnceWrapper);

    return {
      remove: removeListener
    };
  });

  // Perform a check and return a promise with the response
  const performCheck = permissionLogger.wrap(function performCheck(
    options?: Partial<PermissionRequestOptions>
  ) {
    return new Promise<DetailedPermissionResponse>((resolve) => {
      performAddListener(({ response }) => resolve(response), {
        invokeOnce: true,
        trace: options?.trace && `${options?.trace}.performCheck`
      });
      performCheckOrRequest(optionsWithDefaults(options, false));
    });
  });

  // Perform a request and return a promise with the response
  const performRequest = permissionLogger.wrap(function performRequest(
    options?: Partial<PermissionRequestOptions>
  ) {
    return new Promise<DetailedPermissionResponse>((resolve) => {
      performAddListener(({ response }) => resolve(response), {
        invokeOnce: true,
        trace: options?.trace && `${options?.trace}.performRequest`
      });
      performCheckOrRequest(optionsWithDefaults(options, true));
    });
  });

  // Hide the modal
  const hideModal = () => updateModal?.({ visible: false });

  // Add a listener for handling various status updates
  performAddListener(
    permissionLogger.wrap(async function statusListener({
      response: { status, delayed, dismissed }
    }) {
      if (delayed) {
        permissionLogger.inspect(
          await Persistence.fetch(delayKey, () => true, { maxAge }),
          "nextDelay"
        );
      }
      if (status === "granted" || status === "unavailable" || dismissed) {
        hideModal();
      }
    }),
    { trace: "definePermission" }
  );

  const performCheckOrRequest = permissionLogger.wrap(
    async function performCheckOrRequest(
      options: PermissionRequestOptions & { request: boolean }
    ): Promise<void> {
      const logger = options.trace
        ? permissionLogger.createLogger(`!(via: ${options.trace})`)
        : permissionLogger;
      // Decide if the check is allowed to delay
      const allowDelay = !options?.required;

      // Show or update the modal given the status
      const maybeShowModal = logger.wrap(async function maybeShowModal({
        status,
        delayed,
        dismissed,
        trace
      }: DetailedPermissionResponse): Promise<void> {
        trace = trace && `${trace}.maybeShowModal(${status})`;
        if (
          status === "unavailable" ||
          status === "granted" ||
          (options.allowLimited && status === "limited")
        ) {
          notifyChange({ status, delayed: false, trace });
          return;
        }
        if (
          (delayed || dismissed) &&
          !shownNative &&
          !options.forceAsk &&
          !options.required
        ) {
          return notifyChange({ status, delayed, dismissed, trace });
        }

        shownNative = false;

        // Decide if we go through another modal flow given a status update
        const handleStatusUpdate = logger.wrap(function handleStatusUpdate({
          response: { status, dismissed, delayed, trace }
        }: {
          response: DetailedPermissionResponse;
        }): unknown {
          trace = trace && `${trace}(${status})`;
          listener.remove();
          logger.debug("shownNative", shownNative);

          if (status === "denied" && shownNative) {
            return handleStatusUpdate({
              response: {
                status: "blocked",
                dismissed,
                delayed,
                trace
              }
            });
          }

          if (dismissed) options.onDismiss?.(status);

          const shouldShowModal =
            !dismissed &&
            !!(
              (options.preview && !delayed) ||
              options.required ||
              options.forceAsk
            );

          logger.info("handleStatusUpdate shouldShowModal", {
            shouldShowModal,
            preview: options.preview,
            delayed,
            required: options.required,
            forceAsk: options.forceAsk
          });

          switch (status) {
            case "denied":
              break;
            case "blocked":
              if (shouldShowModal || shownNative) {
                return maybeShowModal({
                  status,
                  delayed,
                  dismissed,
                  trace
                });
              }
              break;
            default:
              hideModal();
              return notifyChange({
                status,
                delayed,
                dismissed,
                trace
              });
          }
        });

        const listener = performAddListener(handleStatusUpdate, {
          invokeOnce: true,
          trace: trace && `${trace}.handleStatusUpdate`
        });

        const actions: ModalAction[] = [];

        const canDismiss = allowDelay || !!options.onDismiss || dismissed;

        // Dismiss with the given status
        const handleDismiss = once(
          logger.wrap(async function handleDismiss() {
            notifyChange({
              status,
              delayed: allowDelay,
              dismissed: true,
              trace: trace && `${trace}.handleDismiss`
            });
          })
        );

        if (canDismiss) {
          actions.push({
            testID: "delay-permission",
            label: "Not now",
            autoClose: true,
            onPress: () => {
              logger.info("Choose Not now");
              handleDismiss();
            }
          });
        }

        if (status === "blocked" || status === "limited") {
          if (openSettingsFn) {
            actions.push({
              testID: "open-system-settings",
              label: "Go to Settings",
              autoClose: false,
              onPress: () => {
                if (openSettingsFn) {
                  logger.info("Choose Open Settings");
                  openSettingsFn();
                }
              }
            });
          }
        } else {
          // Show the allow action
          actions.push({
            testID: "request-permission",
            label: "Allow",
            autoClose: false,
            onPress: async () => {
              logger.info("Choose Allow");
              const response = await performNativeRequest(status);
              handleStatusUpdate({
                response
              });
            }
          });
        }
        // Show/Update the modal instance
        updateModal?.({
          eventContext: `${name} Permission Modal`,
          title: options.required ? "Missing Required Permission" : "",
          variant: "full",
          onRequestClose: handleDismiss,
          visible: true,
          children: (
            <PermissionsRequestModal
              icon={icon}
              name={name}
              reason={options.reason}
            />
          ),
          dismissable: !!canDismiss,
          actions
        });
      });

      let shownNative = false;
      const performNativeRequest = logger.wrap(
        async function peformNativeRequest(
          status: PermissionStatus
        ): Promise<DetailedPermissionResponse> {
          blockAppStateListener = true;
          const nextStatus = shownNative
            ? "blocked"
            : await requestFn(options)
                .then((status) => (status === "denied" ? "blocked" : status))
                .catch((error) => {
                  logger.error("peformNativeRequest error", error);
                  return status;
                })
                .finally(() => {
                  logger.info("peformNativeRequest finally");
                  blockAppStateListener = false;
                  shownNative = Platform.OS === "android";
                });
          const delayed = nextStatus !== "granted" && allowDelay;
          return {
            status: nextStatus,
            delayed,
            trace: options.trace && `${options.trace}.performNativeRequest`
          };
        },
        "performNativeRequest"
      );

      const { status, delayed } = await checkFnWithDelay(
        `${options.trace}.performCheckOrRequest`
      );

      // Just notify the change unless we are required to show a modal
      if (
        ["granted", "unavailable"].includes(status) ||
        (!options.request && !options.required && !options.forceAsk)
      ) {
        return notifyChange({
          status,
          delayed,
          trace: options.trace && `${options.trace}.${status}`
        });
      }

      // Perform a native request
      if (
        status === "denied" &&
        !options.preview &&
        (!delayed || options.forceAsk)
      ) {
        return performNativeRequest(status).then(maybeShowModal);
      }

      // Maybe show the modal
      maybeShowModal({
        status,
        delayed,
        trace: options.trace && `${options.trace}.${status}`
      });
    }
  );

  const register: Implementation["register"] = (next) => {
    checkFn = permissionLogger.wrap(next.check, "checkFn");
    requestFn = permissionLogger.wrap(next.request, "requestFn");
    openSettingsFn =
      typeof next.openSettings === "boolean"
        ? next.openSettings
        : permissionLogger.wrap(next.openSettings, "openSettings");
    return {
      request: performRequest,
      check: performCheck,
      addListener: performAddListener,
      logger: permissionLogger
    };
  };

  const unavailable: Implementation["unavailable"] = () =>
    register({
      check: permissionLogger.wrap(unavailableImpl, "unavailableCheck"),
      request: permissionLogger.wrap(unavailableImpl, "unavailableRequest"),
      openSettings: false
    });

  return {
    requestOptions: defaultRequestOptions,
    register,
    unavailable
  };
}
