import { ModalDebug } from "@gigsmart/feature-flags";
import ShadowView from "@gigsmart/shadow-view";
import React, { type ReactNode } from "react";
import { Platform, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useModalContext, useModalDebug } from "../organisms/ModalContext";
import { Icon, type IconName, Pressable, Row, Spacer, Text } from "../quarks";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";
import ContentArea from "./ContentArea";
import IconCircle from "./IconCircle";
import IconText from "./IconText";

interface Props {
  title?: string;
  titleColor?: Color;
  titleAction?: ReactNode;
  icon?: IconName;
  subTitle?: string | JSX.Element;
  subTitleItalic?: boolean;
  children?: ReactNode;
  onClose?: () => void;
}

const ModalHeader = ({
  title,
  titleColor = "primary",
  titleAction = null,
  icon,
  subTitle,
  subTitleItalic = true,
  children,
  onClose
}: Props) => {
  const { currentModalIndex, modalQueueLength } = useModalDebug();
  const { onRequestClose, dismissable, variant } = useModalContext();
  const topInset = useSafeAreaInsets().top;
  const styles = useStyles(({ getUnits }) => ({
    closeIconPos: {
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      width: 40,
      height: 40,
      top: getUnits(2),
      right: getUnits(2)
    },
    titleAction: { marginLeft: "auto", minHeight: getUnits(8) },
    containerShadow: {
      shadowColor: "#000000",
      shadowOpacity: 0.15,
      shadowRadius: 3,
      shadowOffset: { width: 0, height: 3 },
      position: "relative",
      zIndex: 1
    },
    // Android modals already account for statusBar height natively
    containerFull: {
      paddingTop: Platform.OS === "android" ? 0 : topInset
    },
    fullCloseIcon: {
      top: Math.max(topInset, getUnits(2))
    },
    debugOverlay: {
      top: 10,
      left: 10,
      right: 10,
      position: "absolute"
    }
  }));

  // display shadows only when there's at least one rendered component
  const showShadow =
    variant.includes("shadow") && (!!title || !!subTitle || !!children);
  const isFull = variant === "full" || variant === "full-shadow";

  return (
    <ShadowView
      style={[
        showShadow && styles.containerShadow,
        isFull && styles.containerFull
      ]}
    >
      <ContentArea constraint="xsmall">
        {ModalDebug.isEnabled() && (
          <Row style={styles.debugOverlay} justifyContent="center">
            <Text color="danger" align="center">
              Modal Index: {currentModalIndex}, Queue Length: {modalQueueLength}
            </Text>
          </Row>
        )}
        <Spacer size="xlarge" />
        {variant === "prompt" && !!icon && (
          <Row justifyContent="center">
            <Spacer size="large" />
            <IconCircle
              alignSelf="center"
              icon={icon}
              size="extraLarge"
              variant="well"
              color="primary"
            />
            <Spacer size="large" />
          </Row>
        )}
        {!!title && <Spacer />}
        {!!title && (
          <Row justifyContent={titleAction ? "flex-start" : "center"}>
            {variant !== "prompt" && icon ? (
              <IconText
                icon={icon}
                iconColor={titleColor}
                color={titleColor}
                spacing={titleAction ? "medium" : "slim"}
                textWeight="bold"
              >
                {title}
              </IconText>
            ) : (
              <Text
                testID="modal-title"
                variant="header"
                weight="bold"
                align="center"
                color={titleColor}
              >
                {title}
              </Text>
            )}
            {!!titleAction && (
              <View style={styles.titleAction}>{titleAction}</View>
            )}
          </Row>
        )}
        {!!subTitle && <Spacer size="compact" />}
        {!!subTitle && (
          <Text italic={subTitleItalic} align="center" testID="modal-subtitle">
            {subTitle}
          </Text>
        )}
      </ContentArea>
      {children}
      {dismissable && (
        <Pressable
          style={[
            styles.closeIconPos,
            Platform.OS !== "android" && isFull && styles.fullCloseIcon
          ]}
          onPress={onClose ?? onRequestClose}
          eventTargetName="Modal Close Icon"
          eventEntityType="Close"
          testID="modal-close-icon"
        >
          <Icon name="times" size="default" color="primary" />
        </Pressable>
      )}
    </ShadowView>
  );
};

export default ModalHeader;
