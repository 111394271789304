import { definePermission } from "../registry";

export default definePermission({
  name: "Background Location",
  icon: "map-marked",
  defaults: {
    preview: true,
    reason:
      "We need to access your location in the background to provide you with the best experience"
  }
});
