import React, { type ComponentProps, forwardRef, type Ref } from "react";
import { Platform, type Text, type ViewProps } from "react-native";
import { createIconSet } from "react-native-vector-icons";
import type { FontAwesome5IconProps } from "react-native-vector-icons/FontAwesome5";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome5Pro";
import type { Icon } from "react-native-vector-icons/Icon";
import glyphmap from "react-native-vector-icons/glyphmaps/FontAwesome5Pro.json";
import meta from "react-native-vector-icons/glyphmaps/FontAwesome5Pro_meta.json";

import { type TextStyleProp, theme } from "../style";

export type IconName = keyof typeof glyphmap | "gigsmart" | "onfleet";

export type IconVariant = "light" | "solid" | "regular";

const BrandIcon = createIconSet(
  glyphmap,
  "FontAwesome5Brands-Regular",
  "FontAwesome5_Pro_Brands.ttf"
);

const brands = new Set<string>(meta.brands);

type Props = Omit<FontAwesome5IconProps, "name"> &
  ComponentProps<typeof Text> & {
    name: IconName;
    backgroundColor?: string;
    color?: string;
    size?: number;
    style?: TextStyleProp;
    variant?: IconVariant;
    testID?: string;
    pointerEvents?: ViewProps["pointerEvents"];
  };

const StyledIcon = forwardRef(
  (
    {
      style,
      name,
      size,
      color = "blue",
      backgroundColor = "transparent",
      variant = "light",
      ...props
    }: Props,
    ref: Ref<Icon>
  ) => {
    const isBrand = Platform.OS !== "ios" && brands.has(name);

    const IconComponent = isBrand ? BrandIcon : FontAwesomeIcon;
    const variantProps = { [isBrand ? "brand" : variant]: true };

    return (
      <IconComponent
        ref={ref}
        name={name}
        style={[
          {
            color: theme.color.getColor(color),
            fontSize: size ?? theme.font.size.extraLarge,
            backgroundColor: theme.color.getColor(backgroundColor)
          },
          style
        ]}
        {...props}
        {...variantProps}
      />
    );
  }
);

// };

export default StyledIcon;
