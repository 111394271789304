import React from "react";

import Stack from "../atoms/Stack";
import Icon from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";
import { useStyle } from "../style/styles";

interface Props {
  label?: string;
  testID: string;
  onPress: () => void;
  variant?: "light" | "solid";
}

export default function TooltipButton({
  label,
  onPress,
  testID,
  variant = "light"
}: Props) {
  const pressedStyle = useStyle(() => ({
    opacity: 0.8
  }));
  return (
    <Pressable
      testID={testID}
      eventEntityType="TooltipButton"
      eventTargetName="Tooltip Button"
      onPress={onPress}
      style={({ pressed }) => [pressed && pressedStyle]}
    >
      {label ? (
        <Stack horizontal size="compact" alignItems="center">
          <Icon
            name="question-circle"
            size="small"
            color={variant === "light" ? "info" : "primary"}
            variant={variant}
          />
          <Text weight="bold" color={variant === "light" ? "info" : "primary"}>
            {label}
          </Text>
        </Stack>
      ) : (
        <Icon
          name="question-circle"
          size="small"
          color={variant === "light" ? "info" : "primary"}
          variant={variant}
        />
      )}
    </Pressable>
  );
}
