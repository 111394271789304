import { AppState, type AppStateStatus } from "react-native";
import { addInitHook, track } from "../hooks";

export default async () => {
  // Track Application Opens
  let currentAppState: AppStateStatus;

  addInitHook(async () => {
    // Track First Event
    const currentAppState = AppState.currentState;
    handleNextState(currentAppState);
  });

  // Handle Next State
  const handleNextState = (nextAppState: AppStateStatus) => {
    if (nextAppState !== currentAppState) {
      const event = eventMapping[nextAppState];
      if (event) void track(event);
    }
    currentAppState = nextAppState;
  };

  // Track Application Change Events
  const subscription = AppState.addEventListener("change", handleNextState);

  // Track window close on web
  if (window?.addEventListener) {
    window.addEventListener("beforeunload", handleBeforeUnload);
  }

  return () => {
    subscription.remove();
    if (window?.removeEventListener) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  };
};

const handleBeforeUnload = () => {
  const event = eventMapping.background as string;
  if (event) void track(event);
};

const eventMapping: Record<AppStateStatus, string | null> = {
  active: "Application Opened",
  background: null,
  inactive: null,
  unknown: null,
  extension: null
};
