import allSettled from "promise.allsettled";
import type { ComponentType } from "react";
import "@react-native-firebase/analytics";
import { remove } from "lodash";
import type { Primitive } from "utility-types";
import { DekigotoError } from "./error";
import { logger } from "./logger";
import { generateEventName } from "./naming";

export type JsonValue =
  | boolean
  | number
  | string
  | null
  | JsonList
  | JsonMap
  | undefined;
export interface JsonMap {
  [key: string]: JsonValue;
  [index: number]: JsonValue;
}
export type JsonList = JsonValue[];

type MaybePromise<T> = T | Promise<T>;
type Thunk<T> = (() => T) | T;

type HookReturnType<T = void> = Thunk<MaybePromise<T>>;
export type ErrorSeverity =
  | "fatal"
  | "error"
  | "warning"
  | "log"
  | "info"
  | "debug"
  | "critical";

interface EventParts {
  contextName: string | null;
  action: string;
  targetName: string;
  entityType: string;
}

type TrackHook = (
  event: string,
  properties: JsonMap,
  category?: string
) => HookReturnType;

type ScreenHook = (
  name: string,
  properties: JsonMap,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component?: ComponentType<any>
) => HookReturnType;

type ErrorHook = (
  error: DekigotoError,
  severity?: ErrorSeverity,
  context?: {
    tags?: Record<string, Primitive>;
    fingerprint?: string[];
  }
) => HookReturnType;

type IdentifyHook = (userId: string | null, traits: JsonMap) => HookReturnType;
type InitHook = () => HookReturnType;
type AliasHook = (prevId: string | null, newId: string) => HookReturnType;
type ReportDeviceIdHook = (deviceId: string) => HookReturnType;
type ResetHook = () => HookReturnType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createHookStore<HookType extends (...args: any[]) => HookReturnType>(
  name: string
): [
  (hook: HookType) => () => void,
  (...args: Parameters<HookType>) => () => void,
  (
    beforeHook: (
      ...args: Parameters<HookType>
    ) => MaybePromise<Parameters<HookType> | void>
  ) => void
] {
  type BeforeHook = (
    ...args: Parameters<HookType>
  ) => MaybePromise<Parameters<HookType> | void>;

  const beforeHooks: BeforeHook[] = [];
  const hooks: HookType[] = [];

  function addHook(fn: HookType) {
    logger.info("[dekigoto:addHook]", name);
    hooks.push(fn);
    return () => remove<HookType>(hooks, fn);
  }

  function addBeforeHook(fn: BeforeHook) {
    beforeHooks.push(fn);
    return () => remove<BeforeHook>(beforeHooks, fn);
  }

  function invoke(...args: Parameters<HookType>) {
    logger.info("[dekigoto:invoke]", name, ...args);
    const callHook = async (hook: HookType) => {
      try {
        const finalArgs = await beforeHooks.reduce<
          Promise<Parameters<HookType>>
        >(async (args, beforeHook) => {
          const nextArgs = await args;
          const result = await beforeHook(...nextArgs);
          return result || nextArgs;
        }, Promise.resolve(args));
        return await hook(...finalArgs);
      } catch (e) {
        console.error(e);
      }
    };
    const callbacks = hooks.map(callHook);
    return async () => {
      const called = await allSettled(callbacks);
      return called.map(async (cb) => {
        if (cb.status === "fulfilled") {
          try {
            if (typeof cb.value === "function") await cb.value();
          } catch (e) {
            console.error(e);
          }
        } else {
          console.error(cb.reason);
        }
      });
    };
  }
  return [addHook, invoke, addBeforeHook];
}

export const [addIdentifyHook, identify, beforeIdentify] =
  createHookStore<IdentifyHook>("identify");
const [addAliasHook, internalAlias, beforeAlias] =
  createHookStore<AliasHook>("alias");
export const [addInitHook, init] = createHookStore<InitHook>("init");
const [addErrorHook, internalCaptureError, beforeError] =
  createHookStore<ErrorHook>("error");
export const [addReportDeviceIdHook, reportDeviceId, beforeReportDeviceId] =
  createHookStore<ReportDeviceIdHook>("reportDeviceId");

const [addScreenHook, internalScreen, beforeScreen] =
  createHookStore<ScreenHook>("screen");
const [addResetHook, internalReset, beforeReset] =
  createHookStore<ResetHook>("reset");
const [addTrackHook, internalTrack, beforeTrack] =
  createHookStore<TrackHook>("track");

export const collectHooks = (hooks: Array<() => void>) => {
  return () => {
    hooks.forEach((dispose) => dispose());
  };
};

export function captureError(
  error: Error,
  severity?: ErrorSeverity,
  context?: {
    tags?: Record<string, Primitive>;
    fingerprint?: string[];
  }
) {
  if (
    "isCritical" in error &&
    typeof error.isCritical === "function" &&
    !error.isCritical()
  ) {
    return;
  }
  return internalCaptureError(new DekigotoError(error), severity, context);
}

export { addErrorHook, beforeError };

let currentUserId: string | null = null;

beforeIdentify((userId: string | null) => {
  currentUserId = userId;
});

export const alias = (userId: string) => {
  internalAlias(currentUserId, userId);
};

export function screen(
  name: string,
  properties: JsonMap = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ComponentType<any>
) {
  return internalScreen(
    name,
    component
      ? { ...properties, name: component.displayName ?? component.name }
      : properties,
    component
  );
}

export function track(
  parts: EventParts | string,
  properties: JsonMap = {},
  category = "event"
) {
  const event = generateEventName(parts);
  return internalTrack(event, properties, category);
}

export function reset() {
  identify(null, {});
  internalReset();
}

export {
  addTrackHook,
  addResetHook,
  addScreenHook,
  beforeAlias,
  beforeReset,
  beforeScreen,
  beforeTrack,
  addAliasHook
};
