import AsyncStorage from "@react-native-async-storage/async-storage";
import { throttle } from "lodash";
import { createContext, useContext } from "react";
import {
  type ComponentType,
  type ReactElement,
  useEffect,
  useState
} from "react";
import Rox from "./impl";

declare global {
  interface Window {
    featureFlags: () => Record<string, FlagSpec>;
  }
}

interface FeatureRendererProps {
  children: ReactElement | null;
}

export enum FlagType {
  REMOTE,
  DEBUG,
  PINNED,
  DISABLED
}

const Context = createContext<unknown>(null);
export const UseFeatureProvider = Context.Provider;

export interface FlagSpec {
  flagType: FlagType;
  readonly name: string;
  readonly flag?: Rox.Flag;
  readonly pin: () => void;
  readonly IfEnabled: ComponentType<FeatureRendererProps>;
  readonly IfDisabled: ComponentType<FeatureRendererProps>;
  readonly Switch: ComponentType<{
    renderEnabled: () => ReactElement | null;
    renderDisabled: () => ReactElement | null;
  }>;
  readonly isOveridden: () => boolean;
  readonly isEnabled: () => boolean;
  readonly getEnabledParent: () => FlagSpec | undefined;
  readonly isToggleable: () => boolean;
  readonly isDisabled: () => boolean;
  readonly isIndividuallyEnabled: () => boolean;
  readonly isBlockedByDeps: () => boolean;
  readonly dependsOn: FlagSpec[];
  readonly inherits?: FlagSpec;
  readonly capability: boolean;
  readonly set: (
    value: boolean,
    forceLocal?: boolean,
    persist?: boolean
  ) => Promise<void>;
  readonly enable: (forceLocal?: boolean, persist?: boolean) => Promise<void>;
  readonly useIsEnabled: () => boolean;
  readonly useIsDisabled: () => boolean;
  readonly disable: (forceLocal?: boolean, persist?: boolean) => Promise<void>;
  readonly reset: (persist?: boolean) => Promise<void>;
  select: (<T>(trueValue: T) => T | undefined) &
    (<T, F>(trueValue: T, falseValue: F) => T | F);
  readonly description: string;
}

export interface SectionSpec {
  readonly name: string;
  readonly description?: string;
  readonly flags: Record<string, FlagSpec>;
  readonly registerFlag: (name: string, opts?: RegisterFlagOpts) => FlagSpec;
}

const envFlags = String(process.env.FEATURE_FLAGS).split(",");
export const sections: SectionSpec[] = [];

export const registerSection = (name: string, description?: string) => {
  const flags: Record<string, FlagSpec> = {};
  const registerFlag = (
    name: string,
    {
      description,
      type = FlagType.REMOTE,
      dependsOn = [],
      capability = false,
      inherits
    }: RegisterFlagOpts = {}
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    if (flags[name]) return flags[name]!;
    if (process.env.IS_TESTING === "true" && type === FlagType.REMOTE) {
      type = FlagType.DEBUG;
    }
    if (envFlags.includes(name)) {
      type = FlagType.PINNED;
    }
    if (envFlags.includes(`~${name}`)) {
      type = FlagType.DEBUG;
    }
    if (envFlags.includes(`!${name}`)) {
      type = FlagType.DISABLED;
    }
    let localOverride: null | boolean = null;
    const deps = Array.isArray(dependsOn) ? dependsOn : [dependsOn];
    const flag = type === FlagType.REMOTE ? new Rox.Flag() : undefined;
    let pinnedState = type === FlagType.PINNED ? true : undefined;
    const disabledState = type === FlagType.DISABLED ? false : undefined;

    const isRemotelyEnabled = throttle(() => flag?.isEnabled(), 1000);

    const isIndividuallyEnabled = () =>
      pinnedState ??
      disabledState ??
      localOverride ??
      inherits?.isEnabled() ??
      isRemotelyEnabled() ??
      false;

    const isEnabled = () =>
      deps.every((dep) => dep.isEnabled()) && isIndividuallyEnabled();

    const isBlockedByDeps = () => isIndividuallyEnabled() && !isEnabled();

    const isDisabled = () => !isEnabled();

    const set = async (
      value: boolean,
      forceLocal = type === FlagType.DEBUG,
      persist = true
    ) => {
      if (value) {
        await Promise.all(
          deps.map(async (dep) => await dep.enable(forceLocal, persist))
        );
      }
      forceLocal
        ? await setLocalOverride(value, persist)
        : Rox.overrides.setOverride(`mobile.${flag}`, String(value));
    };

    const enable = async (
      forceLocal = type === FlagType.DEBUG,
      persist = true
    ) => {
      await set(true, forceLocal, persist);
    };

    const setLocalOverride = async (value: boolean, persist = true) => {
      if (persist) await AsyncStorage.setItem(`Feature$${name}`, String(value));
      localOverride = value;
    };

    const clearLocalOverride = async (persist = true) => {
      if (persist) await AsyncStorage.removeItem(`Feature$${name}`);
      localOverride = null;
    };

    const reset = async (persist = true) => {
      await clearLocalOverride(persist);
      Rox.overrides.clearOverride(`mobile.${flag}`);
    };

    const disable = async (forceLocal?: boolean, persist = true) => {
      await set(false, forceLocal, persist);
    };

    const select = <T, F>(trueValue: T, falseValue?: F): T | F =>
      isEnabled() ? trueValue : (falseValue as F);

    const getEnabledParent = (): FlagSpec | undefined => {
      if (type === FlagType.PINNED) return;
      if (isEnabled() && inherits?.isEnabled())
        return inherits.getEnabledParent();
    };

    const isToggleable = (): boolean =>
      !getEnabledParent() && [FlagType.DEBUG, FlagType.REMOTE].includes(type);

    const spec: FlagSpec = {
      name,
      flag,
      flagType: type,
      inherits,
      getEnabledParent,
      isOveridden: () => type === FlagType.REMOTE && localOverride !== null,
      isEnabled,
      IfEnabled: ({ children }) => (!!isEnabled() && children) || null,
      IfDisabled: ({ children }) => (!!isDisabled() && children) || null,
      Switch: ({ renderEnabled, renderDisabled }) =>
        isEnabled() ? renderEnabled() : renderDisabled(),
      isIndividuallyEnabled,
      isBlockedByDeps,
      isDisabled,
      isToggleable,
      enable,
      disable,
      set,
      pin: () => {
        spec.flagType = FlagType.PINNED;
        type = FlagType.PINNED;
        pinnedState = true;
      },
      capability,
      reset,
      dependsOn: deps,
      select,
      useIsEnabled: () => {
        const [enabled, setEnabled] = useState(isRemotelyEnabled() ?? false);
        const changeKey = useContext(Context);
        useEffect(() => {
          const nextEnabled = isEnabled();
          if (enabled !== nextEnabled) setEnabled(nextEnabled);
        }, [enabled, changeKey]);
        return enabled;
      },
      useIsDisabled: () => !spec.useIsEnabled(),
      description: description ?? name
    };

    flags[name] = spec;

    return spec;
  };

  const section: SectionSpec = { name, description, flags, registerFlag };
  sections.push(section);
  return section;
};

export const getFlags = () =>
  sections.reduce<Record<string, FlagSpec>>(
    (acc, { flags }) => ({ ...acc, ...flags }),
    {}
  );
if (typeof window !== "undefined") {
  window.featureFlags = () => getFlags();
}

export const resetFeature = async (flag: string, persist = true) => {
  const flags = getFlags();
  const flagSpec = flags[flag];
  if (!flagSpec) throw new Error(`${flag} not registered`);
  await flagSpec.reset(persist);
};

export const enableFeature = async (
  flag: string,
  forceLocal?: boolean,
  persist = true
) => {
  const flags = getFlags();
  const flagSpec = flags[flag];
  if (!flagSpec) throw new Error(`${flag} not registered`);
  await flagSpec.enable(forceLocal, persist);
};

export const enableFeatures = async (flags: string[], forceLocal?: boolean) => {
  if (forceLocal) {
    const flagsToSet: Array<[string, string]> = flags.map((key) => [
      `Feature$${key}`,
      String(true)
    ]);
    await AsyncStorage.multiSet(flagsToSet);
  }
  await flags.reduce(async (acc, flag) => {
    await acc;
    await enableFeature(flag, forceLocal, false);
  }, Promise.resolve());
};

export const disableFeature = async (
  flag: string,
  forceLocal?: boolean,
  persist = true
) => {
  const flags = getFlags();
  const flagSpec = flags[flag];
  if (!flagSpec) throw new Error(`${flag} not registered`);
  await flagSpec.disable(forceLocal, persist);
};

export const disableFeatures = async (
  flags: string[],
  forceLocal?: boolean
) => {
  if (forceLocal) {
    const flagstoUnset: Array<[string, string]> = flags.map((key) => [
      `Feature$${key}`,
      String(false)
    ]);
    await AsyncStorage.multiSet(flagstoUnset);
  }
  await flags.reduce(async (acc, flag) => {
    await acc;
    await disableFeature(flag, forceLocal, false);
  }, Promise.resolve());
};

export const isFeatureEnabled = async (flag: string): Promise<boolean> => {
  const flags = getFlags();
  return flags[flag]?.isEnabled() ?? false;
};

export const resetFeatures = async () => {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  const flags = getFlags();
  const keys = await AsyncStorage.getAllKeys();
  const featureKeys = keys.filter((key) => key.startsWith("Feature$"));
  await AsyncStorage.multiRemove(featureKeys);
  await Promise.all(
    Object.values(flags).map(async (flag) => await flag.reset(false))
  );
};

export const getRoxFlags = () => {
  const flags = getFlags();
  return Object.entries(flags).reduce(
    (acc, [key, { flag }]) => (flag ? { ...acc, [key]: flag } : acc),
    {}
  );
};

export const flagStates = (onlyCapabilities = true) => {
  const flags = getFlags();
  return Object.keys(flags)
    .sort()
    .map<[string, FlagSpec]>((key) => [key, flags[key] as FlagSpec])
    .filter(([_, { capability }]) => !onlyCapabilities || capability)
    .reduce(
      (acc, [key, { isEnabled }]) => ({ ...acc, [key]: isEnabled() }),
      {}
    );
};

interface RegisterFlagOpts {
  type?: FlagType;
  dependsOn?: FlagSpec | FlagSpec[];
  capability?: boolean;
  description?: string;
  inherits?: FlagSpec;
}

export async function loadOverrides() {
  const flags = getFlags();
  const values = await AsyncStorage.multiGet(
    Object.keys(flags).map((key) => `Feature$${key}`)
  );
  await Promise.all(
    values.map(async ([k, v]) => {
      const flagName = k.split("Feature$")[1];
      if (!flagName) return;
      const spec = flags[flagName];
      if (!spec) return;
      const value = parseBool(v);
      if (typeof value === "boolean") {
        await spec.set(value, true, false);
      } else {
        await spec.reset(false);
      }
    })
  );
}

function parseBool(v: string | null): boolean | null {
  if (!v) return null;
  return { true: true, false: false }[v] ?? null;
}
