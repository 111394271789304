import React, { useEffect, useState } from "react";
import Icon from "../../quarks/Icon";
import MapCircle from "./MapCircle";
import MapMarker from "./MapMarker";

interface Props {
  center: { lat: number; lng: number };
  radius: number;
  draggable?: boolean;
  onDragEnd?: (lat: number, lng: number) => void;
  onDragStart?: (lat: number, lng: number) => void;
}

export default function MapCirclePin({
  center: initialCenter,
  radius,
  draggable,
  onDragEnd,
  onDragStart
}: Props) {
  const [center, setCenter] = useState(initialCenter);
  const [isDragging, setIsDragging] = useState(false);
  useEffect(() => {
    if (
      initialCenter?.lat !== center?.lat ||
      initialCenter?.lng !== center?.lng
    ) {
      setCenter(initialCenter);
    }
  }, [initialCenter]);

  return (
    <>
      {isDragging && (
        <MapMarker center={initialCenter}>
          <Icon
            name="map-marker-alt"
            color="disabled"
            variant="solid"
            size="default"
          />
        </MapMarker>
      )}
      <MapMarker
        center={center}
        draggable={draggable}
        onDrag={(lat, lng) => setCenter({ lat, lng })}
        onDragEnd={(lat, lng) => {
          setIsDragging(false);
          onDragEnd?.(lat, lng);
        }}
        onDragStart={(lat, lng) => {
          setIsDragging(true);
          onDragStart?.(lat, lng);
        }}
      >
        <Icon
          name="map-marker-alt"
          color="danger"
          variant="solid"
          size="default"
        />
      </MapMarker>
      <MapCircle center={center} radius={radius} />
    </>
  );
}
