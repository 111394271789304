import { type MapProps, Map as RNMapView } from "@vis.gl/react-google-maps";
import React, { type LegacyRef, type ReactNode, useRef } from "react";
import type { LatLng } from "react-native-maps";
import Column from "../../quarks/Column";
import { latlng } from "../helpers";
import MapControls from "./MapControls";

interface Props {
  id: string;
  lite?: boolean;
  children?: ReactNode;
  mapRef?: LegacyRef<typeof RNMapView>;
  onMapReady?: MapProps["onTilesLoaded"];
  center: LatLng;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  onPress?: MapProps["onClick"];
  testID: string;
  topLeft?: ReactNode;
  topCenter?: ReactNode;
  topRight?: ReactNode;
  bottomLeft?: ReactNode;
  bottomCenter?: ReactNode;
  bottomRight?: ReactNode;
}

const MapView = ({
  lite,
  children,
  onMapReady,
  center,
  zoom = 16,
  maxZoom = 17,
  minZoom = 4,
  onPress,
  testID,
  topLeft,
  topCenter,
  topRight,
  bottomLeft,
  bottomCenter,
  bottomRight,
  id
}: Props) => {
  return (
    <Column fill>
      <MapControls
        id={id}
        minZoom={minZoom}
        maxZoom={maxZoom}
        {...{
          topLeft,
          topCenter,
          topRight,
          bottomLeft,
          bottomCenter,
          bottomRight
        }}
      />
      <RNMapView
        id={testID}
        mapId={process.env.GOOGLE_MAPS_MAP_ID}
        defaultCenter={latlng.toLiteral(center)}
        defaultZoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        onClick={onPress}
        onTilesLoaded={onMapReady}
        clickableIcons={false}
        fullscreenControl={false}
        zoomControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        styles={[
          {
            elementType: "labels",
            featureType: "poi.business",
            stylers: [{ visibility: "off" }]
          }
        ]}
        {...(lite && {
          liteMode: true,
          pointerEvents: "none",
          rotateEnabled: false,
          zoomEnabled: false,
          scrollEnabled: false,
          pitchEnabled: false
        })}
      >
        {children}
      </RNMapView>
    </Column>
  );
};

export const useMapRef = () => useRef<typeof RNMapView>(null);

export default MapView;
