import polyline from "@mapbox/polyline";
import { computeDestinationPoint } from "geolib";
import React, { useMemo } from "react";
import { Image } from "react-native";
import { useStyle } from "../style";

interface Props {
  center: { latitude: number; longitude: number };
  geofenceRadius?: number;
  height?: number;
  width?: number;
  zoom?: number;
}

const METERS_PER_MILE = 1609.34;

export default function StaticMap({
  center,
  geofenceRadius,
  height = 250,
  width = 400,
  zoom
}: Props) {
  const style = useStyle(
    { width: "100%", maxWidth: width, aspectRatio: width / height },
    [width, height]
  );
  const url = useMemo(() => {
    const numVertices: number = 128;
    const coordinates: [number, number][] = [];
    if (geofenceRadius) {
      for (let i = 0; i <= numVertices; i++) {
        const angle = i * (360 / numVertices);
        const destination = computeDestinationPoint(
          { latitude: center?.latitude, longitude: center?.longitude },
          geofenceRadius * METERS_PER_MILE,
          angle
        );
        const lat = destination.latitude;
        const lon = destination.longitude;
        coordinates.push([lat, lon]);
      }
    }
    // Encode coordinates using the Google Polyline Algorithm
    const encoded_polyline: string = polyline.encode(coordinates);

    // URL-encode the polyline
    const encodedPolylineParam: string = encodeURIComponent(encoded_polyline);

    const marker = `pin-s-circle+dc4405(${center?.longitude},${center?.latitude})`;
    // Combine style and encoded polyline
    const polylineString = geofenceRadius
      ? `path-2+003b5c-0.2+003b5c-0.2(${encodedPolylineParam}),`
      : "";
    const mapSettingsString = zoom
      ? `${center?.longitude},${center?.latitude},${zoom}`
      : "auto";
    const apiUrl: string = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/${polylineString}${marker}/${mapSettingsString}/${width}x${height}`;
    // Full API request URL
    return `${apiUrl}?access_token=${process.env.MAPBOX_PUBLIC_TOKEN}`;
  }, [center, geofenceRadius]);
  return <Image style={style} resizeMode="contain" source={{ uri: url }} />;
}
