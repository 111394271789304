import React, {
  type ComponentProps,
  type ComponentType,
  type Dispatch
} from "react";
import type { UseQueryLoaderLoadQueryOptions } from "react-relay";
import type {
  GraphQLTaggedNode,
  OperationType,
  Variables
} from "relay-runtime";
import type {
  FragmentContainerInnerComponentProps,
  KeyType
} from "../fragment";
import {
  type CreateSuspendedQueryContainerOptions,
  type OperationWithVariables,
  type SuspendedQueryContainerInnerComponentProps,
  createSuspendedQueryContainer
} from "../query/createSuspendedQueryContainer";
import { createRelaySubscribedFragmentContainer } from "../subscription";

type NodeContainerOptions<
  F extends KeyType<unknown>,
  Q extends OperationWithVariables<V>,
  S extends OperationWithVariables<V>,
  P extends Record<string, unknown>,
  V extends Variables
> = CreateSuspendedQueryContainerOptions<P, V> & {
  getQueryFragmentRef: (props: Q["response"]) => F | null;
  getSubscriptionFragmentRef: (props: S["response"]) => F | null | undefined;
  fragment: GraphQLTaggedNode;
  subscription: GraphQLTaggedNode;
  subscribe?: (
    props: FragmentContainerInnerComponentProps<
      F,
      NodeContainerInnerComponentProps<Q, P>
    >
  ) => boolean;
  subscriptionVariables?:
    | V
    | ((
        props: FragmentContainerInnerComponentProps<
          F,
          NodeContainerInnerComponentProps<Q, P>
        >
      ) => V);
};

export type NodeContainerInnerComponentProps<
  Q extends OperationType,
  P extends {} = {},
  V extends Variables = Q["variables"]
> = P & {
  variables?: V;
  retry: (options?: UseQueryLoaderLoadQueryOptions) => void;
  setVariables: Dispatch<React.SetStateAction<V>>;
};

export function createRelayNodeContainer<
  F extends KeyType<unknown> = never,
  Q extends OperationWithVariables<V> = never,
  S extends OperationWithVariables<V> = never,
  P extends {} = {},
  V extends Variables = Q["variables"] & S["variables"]
>(
  Component: ComponentType<
    FragmentContainerInnerComponentProps<
      F,
      NodeContainerInnerComponentProps<Q, P>
    >
  >,
  {
    getQueryFragmentRef,
    getSubscriptionFragmentRef,
    fragment,
    subscription,
    ...options
  }: NodeContainerOptions<F, Q, S, P, V>
) {
  const SubscribedFragmentContainer = createRelaySubscribedFragmentContainer<
    F,
    S,
    ComponentProps<typeof Component>
  >(Component, {
    fragment,
    subscription,
    getSubscriptionFragmentRef,
    subscriptionVariables: options.subscriptionVariables || options.variables,
    subscribe: options.subscribe
  });

  function NodeContainer(
    props: SuspendedQueryContainerInnerComponentProps<
      Q,
      P & Omit<ComponentType<typeof SubscribedFragmentContainer>, "fragmentRef">
    >
  ) {
    return (
      <SubscribedFragmentContainer
        fragmentRef={getQueryFragmentRef(props.response) as F}
        {...props}
      />
    );
  }

  const QueryContainer = createSuspendedQueryContainer<Q, P, V>(
    NodeContainer,
    options as CreateSuspendedQueryContainerOptions<P, V>
  );

  const DynamicContainer = (
    props:
      | ComponentProps<typeof QueryContainer>
      | ComponentProps<typeof SubscribedFragmentContainer>
  ) =>
    "fragmentRef" in props ? (
      <SubscribedFragmentContainer {...props} />
    ) : (
      <QueryContainer {...props} />
    );

  DynamicContainer.displayName = `RelayNodeContainer(${
    Component.displayName ?? Component.name
  })`;

  return DynamicContainer;
}
