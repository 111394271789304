import { useTheme } from "@gigsmart/atorasu/style";
import Polyline from "@mapbox/polyline";
import React from "react";
import { Polyline as MapsPolyline } from "react-native-maps";

interface Props {
  encodedPolyline: string;
}

export default function PolylineRoute({ encodedPolyline }: Props) {
  if (!encodedPolyline) return null;
  const path = Polyline.decode(encodedPolyline).map(
    ([latitude, longitude]) => ({
      latitude,
      longitude
    })
  );
  const { getColor } = useTheme();

  return (
    <MapsPolyline
      coordinates={path}
      strokeColor={getColor("orange", "fill")}
      strokeWidth={3}
    />
  );
}
