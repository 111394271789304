import * as Sentry from "@sentry/react";

const buildNumber = process.env.PACKAGE_BUILD_NUMBER ?? "";

const config: Sentry.BrowserOptions = {
  release: buildNumber,
  integrations: [new Sentry.BrowserTracing() as any],
  tracePropagationTargets: [
    String(process.env.GRAPHQL_BASE_URL)
      .replace(/\/graphql$/, "/.*")
      .replace(/(https?:\/\/)api\./, "$1.*.")
  ],
  _metadata: {
    sdk: {
      version: Sentry.SDK_VERSION
    }
  }
};

export default config;
