/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EventContext, useEventer } from "@gigsmart/dekigoto";
import {
  type NavigationProp,
  type RouteProp,
  defaultBackHandler,
  useNavigation,
  useRoute
} from "@gigsmart/kaizoku";
import { isEqual } from "lodash";
import React, {
  createContext,
  type ReactElement,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { logger } from "./logger";

export type TBaseStep<T, S = any> = T & {
  stepName: string;
  stepIndicatorLabel?: string;
  component: ReactElement;
  disabled?: boolean | ((data: S) => boolean);
  onEnterStep?: () => void;
};

export type BaseStepData = {
  nextStep?: string | null;
};

export interface StepperContextData<TStep, TStepData> {
  activeStepNum: number;
  activeStep: TBaseStep<TStep, TStepData> | undefined;
  steps: Array<TBaseStep<TStep, TStepData>>;
  hasNextStep: boolean;
  hasPreviousStep: boolean;
  stepsData: TStepData;
  nextStep: (data?: Partial<TStepData>) => void;
  prevStep: (data?: Partial<TStepData>) => void;
  gotoStep: (stepNum: number | string, data?: Partial<TStepData>) => void;
}

const StepperContext = createContext<
  StepperContextData<TBaseStep<any, any>, any>
>({
  activeStepNum: 1,
  activeStep: undefined,
  hasNextStep: false,
  hasPreviousStep: false,
  steps: [],
  stepsData: {},
  nextStep: () => {},
  prevStep: () => {},
  gotoStep: () => {}
});

export { StepperContext };

interface StepperProviderProps<TStep, TStepData> {
  children?: ReactNode;
  stepParam?: string;
  steps: Array<TBaseStep<TStep, TStepData>>;
  initialStepsData?: TStepData;
  eventContext?: string | null;
  initialStep?: number | string;
  onExit?: () => void;
  testID?: string;
  onComplete?: (props: TStepData) => void;
}

/** @deprecated use `createStepper` factory instead. */
export default function StepperProvider<TStep, TStepData extends BaseStepData>({
  children,
  stepParam = "step",
  steps = [],
  initialStepsData,
  initialStep = 1,
  eventContext = null,
  onExit = defaultBackHandler,
  testID,
  onComplete
}: StepperProviderProps<TStep, TStepData>) {
  const trackDecrease = useEventer("Decreased", testID ?? "Unknown", "Stepper");
  const trackIncrease = useEventer("Increased", testID ?? "Unknown", "Stepper");
  const trackSet = useEventer("Set", testID ?? "Unknown", "Stepper");

  const [stepsData, setStepsData] = useState(
    (initialStepsData ?? {}) as TStepData // FIXME: unsafe cast
  );

  const [submitting, setSubmitting] = useState(false);
  const route = useRoute<RouteProp<any>>();
  const navigation = useNavigation<NavigationProp<any>>();
  const step = useMemo(() => {
    const { availableSteps } = computeStepState(steps, stepsData);
    const value = route.params?.[stepParam] ?? initialStep;
    const stepName =
      typeof value === "number" ||
      !Number.isNaN(Number.parseInt(value as string))
        ? availableSteps[Number.parseInt(value as string) - 1]?.stepName
        : (value as string);
    return stepName;
  }, [steps, stepsData, route.params]);

  // allows replacing the step list while next/prev steps is still being processed
  // don't hold array or any references while running next/prev step.
  const stepsRef = useRef(steps);
  useEffect(() => {
    stepsRef.current = steps;
  }, [steps]);

  // submit on mount if there are no available steps
  useEffect(() => {
    const { availableSteps } = computeStepState(steps, stepsData);
    if ((availableSteps?.length ?? 0) === 0) onComplete?.(stepsData);
  }, []);

  const stepperData = useMemo(() => {
    const { availableSteps, safeStepIdx } = computeStepState(
      steps,
      stepsData,
      step
    );

    const increaseOrDecreaseStep =
      (diff: 1 | -1) => (data?: Partial<TStepData>) => {
        let nextStepsData: TStepData = { ...stepsData, ...data };
        if (isEqual(nextStepsData, stepsData)) nextStepsData = stepsData;
        else
          setStepsData((stepsData) => ({
            ...stepsData,
            nextStep: null,
            ...data
          }));

        const { availableSteps, stepIdx } = computeStepState(
          stepsRef.current,
          nextStepsData,
          step
        );

        const nextStep =
          diff === 1 && stepsData?.nextStep
            ? availableSteps.find((it) => it.stepName === stepsData?.nextStep)
                ?.stepName
            : availableSteps[stepIdx + diff]?.stepName;

        setStepsData((stepsData) => ({
          ...stepsData,
          ...data,
          nextStep: null
        }));

        logger.info(
          `[step change] from=${step}(${stepIdx ?? "-"}) to=${
            nextStep ?? (diff > 0 ? "[COMPLETE]" : "[EXIT]")
          }(${stepIdx + diff})`
        );

        if (nextStep) {
          (diff < 0 ? trackDecrease : trackIncrease)();
          navigation.setParams({ [stepParam]: nextStep });
        } else {
          if (diff < 0) onExit?.();
          if (diff > 0 && onComplete) onComplete(nextStepsData);
        }
      };

    return logger.inspect(
      {
        activeStep: availableSteps[safeStepIdx],
        activeStepNum: safeStepIdx + 1,
        hasNextStep: !!availableSteps[safeStepIdx + 1],
        hasPreviousStep: !!availableSteps[safeStepIdx - 1],
        steps: availableSteps,
        stepsData,
        prevStep: increaseOrDecreaseStep(-1),
        nextStep: increaseOrDecreaseStep(1),
        gotoStep: (
          stepNumOrName: number | string,
          data?: Partial<TStepData>
        ) => {
          if (data) setStepsData((stepsData) => ({ ...stepsData, ...data }));
          const isEnabled = (step?: TBaseStep<any>) => {
            if (!step) return false;
            return typeof step.disabled === "function"
              ? !step.disabled({ ...stepsData, ...data })
              : !step.disabled;
          };
          const availableStepsBasedOnNewStepsData = data
            ? steps.filter(isEnabled)
            : availableSteps;

          const next =
            typeof stepNumOrName === "number"
              ? availableStepsBasedOnNewStepsData[stepNumOrName]
              : availableStepsBasedOnNewStepsData.find(
                  (it) => it.stepName === stepNumOrName
                );
          if (!next) return;

          trackSet({
            stepNumber: availableSteps.indexOf(next),
            stepName: next.stepName
          });
          navigation.setParams({ [stepParam]: next.stepName });
        }
      },
      "stepperData"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, steps, stepsData]);

  return (
    <EventContext name={eventContext}>
      <StepperContext.Provider value={stepperData}>
        {children ?? stepperData.activeStep?.component}
      </StepperContext.Provider>
    </EventContext>
  );
}

function computeStepState<T>(
  steps: Array<TBaseStep<unknown, T>>,
  stepsData: T,
  step?: string
) {
  const isEnabled = (step?: TBaseStep<any>) => {
    if (!step) return false;
    return typeof step.disabled === "function"
      ? !step.disabled(stepsData as T)
      : !step.disabled;
  };

  const availableSteps = steps.filter(isEnabled);
  const stepIdx = availableSteps.findIndex((it) => it.stepName === step);
  let safeStepIdx = stepIdx;

  if (stepIdx < 0) {
    let curStepIdx = steps.findIndex((it) => it.stepName);
    // find next available step
    while (++curStepIdx < steps.length) {
      if (isEnabled(steps[curStepIdx])) break;
    }

    safeStepIdx = steps[curStepIdx]
      ? // biome-ignore lint/style/noNonNullAssertion: <explanation>
        availableSteps.indexOf(steps[curStepIdx]!)
      : 0;
  }

  return {
    availableSteps,
    safeStepIdx,
    stepIdx
  };
}
