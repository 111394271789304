import React from "react";
import IconText from "../atoms/IconText";
import Pressable from "../quarks/Pressable";

interface Props {
  onPress?: () => void;
  testID: string;
}

export default function EditButton({ onPress, testID }: Props) {
  return (
    <Pressable
      testID={`${testID}-edit-icon`}
      eventEntityType="Edit Button"
      eventTargetName="Edit Button"
      onPress={onPress}
    >
      <IconText color="primary" icon="edit" size="small">
        Edit
      </IconText>
    </Pressable>
  );
}
