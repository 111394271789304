import { Platform } from "react-native";
import { definePermission } from "../registry";

export default definePermission({
  name: "Push Notifications",
  icon: "bell",
  restrictRequired: Platform.OS === "ios",
  defaults: {
    preview: true,
    reason:
      "We need access to send push notifications to provide you with the best experience."
  }
});
