import "./FancyScroll.css";

import React, { type ReactNode } from "react";
import { StyleSheet } from "react-native";
import SimpleBar from "simplebar-react";

import { useStyles } from "../style";
import Column, { type Props as ColumnProps } from "./Column";

interface Props {
  testID: string;
  children?: ReactNode;
  fill?: boolean;
  style?: ColumnProps["style"];
}

export default function FancyScroll({ style, testID, children, fill }: Props) {
  const styles = useStyles(() => ({
    container: { overflow: "hidden" }
  }));
  return (
    <Column fill={fill} style={[style, styles.container]}>
      <SimpleBar
        style={StyleSheet.absoluteFillObject}
        scrollableNodeProps={{ "data-testid": testID }}
      >
        {children}
      </SimpleBar>
    </Column>
  );
}
