import React, { Component, useMemo, type ReactNode } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { createEnvironmentStub } from "../environment";
import { RelayQueryRetryBoundary } from "../query/retry";
import Context, {
  type RelayOrchestratorProp,
  relayOrchestratorStub
} from "./context";
import { logger } from "./logger";
import {
  type RelayOrchestratorOptions,
  createRelayOrchestrator
} from "./relay-orchestrator";
interface RelayOrchestrationProviderProps extends RelayOrchestratorOptions {
  children: ReactNode;
}

interface State {
  orchestrator: RelayOrchestratorProp | null | undefined;
  error: Error | null;
}

export class RelayOrchestrationProvider extends Component<
  RelayOrchestrationProviderProps,
  State
> {
  static defaultProps = {
    requestHeaders: async () => await Promise.resolve({}),
    onSubscriptionError: console.warn
  };

  mounted = false;

  state: State = {
    orchestrator: null,
    error: null
  };

  // React lifecyle componentDidMount
  async componentDidMount() {
    this.mounted = true;
    logger.info("mounted");
    await this.setOrchestrator();
  }

  // React lifecyle componentWillUnmount
  componentWillUnmount() {
    logger.info("unmounted");
    this.mounted = false;
  }

  setOrchestrator = async () => {
    const { children, beforeReset, afterReset, ...options } = this.props;
    logger.info("create relay orchestrator");
    try {
      const orchestrator = await createRelayOrchestrator({
        beforeReset: async () => {
          if (beforeReset) await beforeReset();
          this.setState({ orchestrator: null });
        },
        afterReset: async () => {
          if (afterReset) await afterReset();
          this.setState({ orchestrator });
        },
        ...options
      });
      if (this.mounted) this.setState({ orchestrator });
    } catch (error: any) {
      logger.error(error);
      this.setState({ error });
    }
  };

  // React lifecyle render
  render() {
    const { orchestrator, error } = this.state;
    if (error) throw error;
    if (!orchestrator) return null;
    return (
      <Context.Provider value={orchestrator}>
        <RelayQueryRetryBoundary>
          <RelayEnvironmentProvider environment={orchestrator.environment}>
            {this.props.children}
          </RelayEnvironmentProvider>
        </RelayQueryRetryBoundary>
      </Context.Provider>
    );
  }
}

export const ResetRelayOrchestrator = ({
  children
}: {
  children: ReactNode;
}) => (
  <Context.Provider value={relayOrchestratorStub}>
    <RelayEnvironmentProvider environment={useMemo(createEnvironmentStub, [])}>
      {children}
    </RelayEnvironmentProvider>
  </Context.Provider>
);
