import noop from "lodash/noop";
import { createContext } from "react";
import { fetchQuery } from "react-relay";
import type {
  CacheConfig,
  FetchQueryFetchPolicy,
  GraphQLTaggedNode,
  IEnvironment,
  MutationParameters,
  OperationType
} from "relay-runtime";
import { createEnvironmentStub } from "../environment";
import type { LoaderFn } from "../loader";
import type { PayloadError } from "../payload-error";
import type { GlobalOnSubscriptionNext, Subscriber } from "../types";
import type { RelayRequestError } from "./relay-orchestrator";

export interface RelayContextOrchestratorOptions {
  readonly onQueryCompleted: <TOperation extends OperationType>(
    response: TOperation["response"]
  ) => unknown;
  readonly onQueryError: (error: RelayRequestError) => unknown;
  readonly onMutationCompleted: <TOperation extends MutationParameters>(
    response: TOperation["rawResponse"] & TOperation["response"]
  ) => unknown;
  readonly onMutationError: (
    error: RelayRequestError,
    retry?: () => void
  ) => unknown | false;
  readonly useDecorateQuery: (
    name: string,
    retry: () => Promise<void> | void
  ) => void;
  readonly onSubscriptionNext: GlobalOnSubscriptionNext | null | undefined;
}

export type RelayOrchestratorProp = RelayContextOrchestratorOptions & {
  readonly enableSubscriptions: boolean;
  readonly environment: IEnvironment;
  readonly alwaysFetch?: boolean;
  readonly payloadErrorTransformer: (error: PayloadError) => PayloadError;
  readonly __UNSAFE_registerLoader: (loader: LoaderFn) => void;
  readonly __UNSAFE_deregisterLoader: (loader: LoaderFn) => void;
  readonly __UNSAFE_registerSubscriber: (subcriber: Subscriber) => void;
  readonly __UNSAFE_deregisterSubscriber: (subcriber: Subscriber) => void;
  readonly reset: (
    nextToken?: string | null | undefined
  ) => Promise<IEnvironment>;
  readonly invokeLoad: (loadStart: Promise<void>) => Promise<void>;
  readonly fetchQuery: <TOperation extends OperationType>(
    query: GraphQLTaggedNode,
    variables: TOperation["variables"],
    options?: {
      fetchPolicy?: FetchQueryFetchPolicy;
      networkCacheConfig?: CacheConfig;
    }
  ) => Promise<TOperation["response"]>;
  // used only for testing purposes
  readonly __environment?: any;
};

export const defaultOptions: RelayContextOrchestratorOptions = {
  onQueryCompleted: () => undefined,
  onQueryError: console.warn,
  onMutationCompleted: () => undefined,
  onMutationError: console.warn,
  onSubscriptionNext: () => undefined,
  useDecorateQuery: noop
};

export const relayOrchestratorStub: RelayOrchestratorProp = {
  ...defaultOptions,
  get environment() {
    return createEnvironmentStub();
  },
  enableSubscriptions: true,
  payloadErrorTransformer: (error: PayloadError) => error,
  __UNSAFE_registerLoader: noop,
  __UNSAFE_deregisterLoader: noop,
  __UNSAFE_registerSubscriber: noop,
  __UNSAFE_deregisterSubscriber: noop,
  reset: async () => await Promise.resolve(createEnvironmentStub()),
  invokeLoad: async (promise: Promise<void>) => await promise,
  fetchQuery: async <TOperation extends OperationType>(
    query: GraphQLTaggedNode,
    variables: TOperation["variables"],
    options?: {
      fetchPolicy?: FetchQueryFetchPolicy;
      networkCacheConfig?: CacheConfig;
    }
  ): Promise<TOperation["response"]> =>
    fetchQuery(createEnvironmentStub(), query, variables, options)
};

export interface RelayOrchestratorProps {
  relay: RelayOrchestratorProp;
}

export const relayOrchestratorStubs = {
  relay: relayOrchestratorStub
};

export default createContext<RelayOrchestratorProp>(relayOrchestratorStub);
