import { Platform } from "react-native";
import {
  type FontRegistry,
  type FontWeightName,
  fontWeights
} from "./fontHelpers";

export const fontFaceStyle = (
  loadFile: () => string | { default: string },
  fontName: string,
  weight?: FontWeightName,
  style?: string
) => {
  let fontUrl = loadFile();
  if (typeof fontUrl === "object") fontUrl = fontUrl.default;
  // if (fontUrl.startsWith("./")) fontUrl = fontUrl.slice(1);

  return `@font-face {
  src: url('/${fontUrl.replace(/^\//, "")}');
  font-family: '${fontName}';${
    weight ? `\n  font-weight: ${fontWeights[weight]};` : ""
  }${style ? `\n  font-style: ${style};` : ""}}`;
};

export default function loadFonts(registry: FontRegistry) {
  // Don't need to load fonts on native apps
  if (Platform.OS !== "web") return;

  const fontFaces = [];
  for (const fontName in registry) {
    const weights = registry[fontName];
    for (const fontWeight in weights) {
      const fontStyles = weights[fontWeight as keyof typeof weights];
      for (const fontStyle in fontStyles) {
        const { loadFile } = fontStyles[fontStyle as keyof typeof fontStyles];
        const fontFace = fontFaceStyle(
          loadFile,
          fontName,
          fontWeight === "*" ? undefined : (fontWeight as FontWeightName),
          fontStyle === "*" ? undefined : fontStyle
        );

        fontFaces.push(fontFace);
      }
    }
  }

  const rule = fontFaces.join("\n\n");

  // Create stylesheet
  const style = document.createElement("style");
  style.setAttribute("type", "text/css");
  style.setAttribute("id", "atorasu-fonts");

  if (style.sheet) {
    style.sheet.insertRule(rule);
  } else {
    style.appendChild(document.createTextNode(rule));
  }

  void document.head?.appendChild(style);
}
