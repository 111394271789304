import React, { Children, type ComponentType, type ReactNode } from "react";
import type { StepConfig } from "./Stepper.types";

export type StepProps<T, TData> = Omit<StepConfig<T, TData>, "key" | "index">;

export const Step: ComponentType<StepProps<any, any>> = () => null;

export const getStepsFromChildren = <T, TData>(
  children: ReactNode,
  prefix = ""
) => {
  return Children.toArray(children).reduce(
    (acc, child, index) => {
      if (React.isValidElement(child)) {
        const { name } = child.props ?? {};
        if (child.type === Step) {
          acc.push({
            ...child.props,
            key: [prefix, name].filter(Boolean).join("."),
            name,
            index
          });
        } else {
          throw new Error(
            "Invalid Step component: Valid types are `Step`, [...]"
          );
        }
      }

      return acc;
    },
    [] as StepConfig<T, TData>[]
  );
};
