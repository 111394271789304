import StyledPicker from "@gigsmart/katana/input/styled-picker";
import { DateTime } from "luxon";
import React, { type ComponentType, useEffect, useMemo, useState } from "react";
import type { LayoutChangeEvent } from "react-native";
import {
  Button,
  ContentArea,
  IsConstrainedProvider,
  Stack,
  Surface
} from "../../atoms";
import { Column, Spacer } from "../../quarks";
import { useMatchesViewport, useStyle } from "../../style";
import { useThrottledCallback } from "../../utils";
import { Context } from "./CalendarContext";
import CalendarHeader from "./CalendarHeader";
import DailyCalendar from "./DailyCalendar";
import MonthlyCalendar from "./MonthlyCalendar";
import WeeklyCalendar from "./WeeklyCalendar";
import {
  type CalendarContext,
  type CalendarVariant,
  type PeriodType,
  monthlyView
} from "./helpers";

const periodComponents: Record<
  PeriodType,
  ComponentType<CalendarVariant<any>>
> = {
  month: MonthlyCalendar,
  week: WeeklyCalendar,
  day: DailyCalendar
};

const PERIOD_OPTIONS = [
  { label: "Month", value: "month" },
  { label: "Week", value: "week" },
  { label: "Day", value: "day" }
];

type Props<T> = Omit<CalendarVariant<T>, "isMd" | "isSm"> & {
  loading?: boolean;
  period: PeriodType;
  onDateChange?: (date: DateTime) => void;
  onPeriodChange?: (period: PeriodType) => void;
};

export default function Calendar<T>({
  period,
  onDateChange,
  onPeriodChange,
  date,
  ...rest
}: Props<T>) {
  const isSm = useMatchesViewport(({ size }) => size.small.up);
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const isLg = useMatchesViewport(({ size }) => size.large.up);

  const [height, setHeight] = useState(0);
  const [rows, setRows] = useState(0);

  const btnWidthStyle = useStyle(
    () => ({ width: isSm ? 84 : undefined }),
    [isSm]
  );
  const CalendarComponent = periodComponents[period];
  const context = useMemo<CalendarContext>(() => {
    // REAL = height - top header + day indicator for each row + border
    const realHeight = height - (isMd ? 32 : 0) - 32 - rows * (32 + 1) - 3;
    return {
      isSm,
      isMd,
      isLg,
      cellSize: height && rows ? realHeight / rows : 0
    };
  }, [isSm, isMd, isLg, rows, height]);

  const handleLayout = useThrottledCallback(
    (e: LayoutChangeEvent) => {
      if (!e.nativeEvent) return;
      const value = e.nativeEvent.layout.height;
      if (value !== height) setHeight(value);
    },
    300,
    [height]
  );

  useEffect(() => {
    if (period === "month") {
      setRows(monthlyView(date).length);
    } else setRows(1);
  }, [period, date, rows]);

  return (
    <IsConstrainedProvider value>
      <Context.Provider value={context}>
        <Surface fill>
          <Surface variant="shadow" color="foreground" zIndex={2}>
            <ContentArea size="medium">
              <Stack horizontal alignItems="center">
                <Column style={btnWidthStyle}>
                  <Button
                    alignSelf="flex-start"
                    size="small"
                    outline
                    label="Today"
                    testID="calendar-today"
                    onPress={() => onDateChange?.(DateTime.now())}
                  />
                </Column>
                <CalendarHeader
                  fill
                  date={date}
                  period={period}
                  onDateChange={onDateChange}
                />
                <Column style={btnWidthStyle} alignItems="flex-end">
                  <StyledPicker
                    value={period}
                    disabled={!rest.events || rest.loading}
                    onChangeText={(period) =>
                      onPeriodChange?.(period as PeriodType)
                    }
                    eventTargetName="Calendar Period Picker"
                    options={PERIOD_OPTIONS}
                    renderPickerPressable={({ isOpen, label }) => (
                      <Button
                        size="small"
                        outline
                        label={label ?? "-"}
                        testID="calendar-period-picker"
                        icon={isOpen ? "chevron-up" : "chevron-down"}
                        iconPlacement="right"
                      />
                    )}
                  />
                </Column>
              </Stack>
            </ContentArea>
          </Surface>
          {!isMd && <Spacer />}
          <ContentArea
            fill
            {...(!isMd && { size: "none", variant: "none" })}
            onLayout={handleLayout}
          >
            <CalendarComponent date={date} {...rest} />
          </ContentArea>
        </Surface>
      </Context.Provider>
    </IsConstrainedProvider>
  );
}
