import React, { type ReactNode } from "react";
import IconCircle from "../atoms/IconCircle";
import Stack from "../atoms/Stack";
import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";
import { useStyles } from "../style/styles";

interface Props {
  label: string | ReactNode;
  icon?: IconName;
  iconCircle?: boolean;
  selected?: boolean;
  onSelect: () => void;
  testID: string;
  variant?: "standard" | "slim";
}

export default function IconRadioButton({
  selected,
  onSelect,
  label,
  icon,
  iconCircle = true,
  variant = "standard",
  testID
}: Props) {
  const styles = useStyles(
    ({ getUnits, getColor }) => {
      const paddingBottom = variant === "standard" ? getUnits(4) : getUnits(2);
      const paddingTop = getUnits(4);
      const paddingHorizontal =
        variant === "standard" ? getUnits(4) : getUnits(2);

      return {
        container: {
          borderRadius: getUnits(1),
          borderColor: getColor("divider", "fill"),
          borderWidth: 1,
          backgroundColor: getColor("clear", "fill"),
          paddingTop,
          paddingBottom,
          paddingHorizontal
        },
        selected: {
          borderColor: getColor("primary", "fill"),
          borderWidth: 2,
          paddingTop: paddingTop - 1,
          paddingBottom: paddingBottom - 1,
          paddingHorizontal: paddingHorizontal - 1
        },
        selectedIcon: {
          position: "absolute",
          top: 8,
          right: 8,
          fontSize: 24
        }
      };
    },
    [variant]
  );
  return (
    <Pressable
      style={[styles.container, selected && styles.selected]}
      onPress={onSelect}
      testID={testID}
      eventTargetName="Icon Radio Button"
      eventEntityType="IconRadioButton"
    >
      {selected && (
        <Icon
          name="check-circle"
          variant="solid"
          color="primary"
          style={styles.selectedIcon}
        />
      )}
      <Stack
        alignItems="center"
        justifyContent="center"
        size="medium"
        horizontal={variant === "slim"}
      >
        {!icon ? null : iconCircle ? (
          <IconCircle
            size="medium"
            icon={icon}
            color="primary"
            variant="well"
          />
        ) : (
          <Icon size="default" name={icon} color="primary" />
        )}
        <Text weight="bold" color="primary">
          {label}
        </Text>
      </Stack>
    </Pressable>
  );
}
