import React, { type ComponentProps } from "react";
import { Button, ContentArea, IconCircle } from "../atoms";
import Card from "../atoms/Card";
import Column from "../quarks/Column";
import type { IconName } from "../quarks/Icon";
import Text from "../quarks/Text";

const colorVariants = {
  default: {
    iconProp: { color: "neutral", iconColor: "disabled" },
    textProp: {}
  },
  primary: {
    iconProp: { color: "primary", variant: "well" },
    textProp: { color: "primary", weight: "bold" }
  }
} as const;

type Props = {
  icon?: IconName;
  variant?: "card" | "flat";
  color?: keyof typeof colorVariants;
  // children?: string | JSX.Element | JSX.Element[];
  title?: string;
  subtitle?: string;
  actionLabel?: string;
  actionIcon?: IconName;
  onAction?: () => void;
  secondaryActionLabel?: string;
  secondaryActionIcon?: IconName;
  onSecondaryAction?: () => void;
  // width?: number;
  contentAreaVariant?: ComponentProps<typeof ContentArea>["variant"];
} & Pick<
  ComponentProps<typeof ContentArea>,
  "fill" | "constraint" | "constrainedVariant"
>;

export default function ListEmpty({
  icon = "times-octagon",
  title,
  subtitle,
  actionLabel,
  actionIcon,
  onAction,
  secondaryActionLabel,
  secondaryActionIcon,
  onSecondaryAction,
  variant = "card",
  color = "default",
  constraint = "xsmall",
  contentAreaVariant,
  // width,
  ...props
}: Props) {
  const { iconProp, textProp } = colorVariants[color];

  const content = (
    <ContentArea>
      <Column gap="standard">
        <Column alignItems="center" justifyContent="center" gap="standard">
          <IconCircle {...iconProp} icon={icon} size="medium" variant="well" />
          <Text
            testID="empty-state-title-txt"
            variant="subheader"
            color="primary"
            align="center"
          >
            {title}
          </Text>
          <Text testID="empty-state-subtitle-txt" align="center">
            {subtitle}
          </Text>
        </Column>
        {(onAction || onSecondaryAction) && (
          <Column gap="compact">
            {secondaryActionLabel && onSecondaryAction && (
              <Button
                testID="list-empty-seconday-action"
                icon={secondaryActionIcon}
                label={secondaryActionLabel}
                onPress={onSecondaryAction}
                size="small"
                outline
              />
            )}
            {onAction && (
              <Button
                testID="list-empty-action"
                icon={actionIcon}
                label={actionLabel}
                onPress={onAction}
                size="small"
              />
            )}
          </Column>
        )}
      </Column>
    </ContentArea>
  );

  return (
    <ContentArea
      testID="list-empty"
      size="none"
      justifyContent="center"
      constraint={constraint}
      variant={contentAreaVariant}
      {...props}
    >
      {variant === "card" ? (
        <Card testID="list-empty-card" eventTargetName="List Empty Card">
          {content}
        </Card>
      ) : (
        content
      )}
    </ContentArea>
  );
}
